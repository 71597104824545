.gs-floated-grid.gs-news-grid{
	.gs-grid-wrap{
		margin: 0 -15px;

		@include media(tablet){
			margin: 0 -10px;
		}
	}

	&.gs-news-page{

		@include media(tablet-medium){
			.gs-grid-item{
				width: 100%;
			}
		}

		.news-list-image{
			@include size(50%, 0);
			display: inline-block;
			vertical-align: top;
			padding-top: 40%;

			@include media(mobile-portrait){
				width: 100%;
				padding-top: 66.785%;
			}

		}

		.news-list-text{
			width: 50%;
			margin-top: 0;
			display: inline-block;
			vertical-align: top;
			padding-left: 15px;

			@include media(mobile-portrait){
				width: 100%;
				margin-top: 20px;
				padding-left: 0;
			}

			.gs-news-date{
				display: none;
			}

			h5{
				padding-right: 0;
			}
		}
	}

	&.gs-rel-news{
		margin-top: 25px;
		padding-top: 25px;
		border-top: 1px solid;
	}

	.gs-grid-item{
		padding: 0 15px;
		margin: 0 0 30px;

		@include media(tablet){
			padding: 0 10px;
		}

		@include media(mobile){
			width: 100%;
		}
	}
}

.gs-news-list{

	.news-list-image{
		@include size(100%, 0);
		padding-top: 66.785%;
		overflow: hidden;
		z-index: 1;

		@include media(hover){
			&:hover{
				.gs-background{
					@include scale(1.05);
				}
			}
		}

		.gs-background{
			backface-visibility: hidden;
			@include transition(all .5s);
		}
	}

	.news-list-text{
		margin-top: 20px;

		.gs-news-date{
			@include absolute(top 2px right 0);
			width: 110px;
			font-size: 12px;
			text-align: right;
			font-style: normal;
		}

		h5{
			font-size: 19px;
			padding-right: 120px;
		}

		p{
			font-size: 14px;
			margin-top: 15px; 
		}

	}

	.gs-read-more{
		margin-top: 15px;
	}

}

.gs-news-wrapper{
	display: flex;
	align-items: flex-start;

	@include media(tablet){
		flex-wrap: wrap;
	}
}

.gs-news-categories{
	flex: 0 0 170px;
	margin-right: 25px;

	@include media(tablet){
		margin: 0 0 25px;
		flex: 0 0 100%;
		z-index: 2;

		&.active{
			.gs-cats-wrapper{
				display: block;
			}
		}
	}

	.gs-mobile-category-button{
		display: none;
		align-items: center;

		@include media(tablet){
			display: flex;
			justify-content: space-between;
			padding: 0 0 10px;
			border-bottom: 1px solid #e5e5e5;
		}

		.icon{
			margin-left: 5px;
			font-size: 10px;
			color: #9a9da0;
		}

		.gs-cat-name{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		span{
			display: inline-block;
			vertical-align: middle;
		}
	}

	.gs-cat-label{
		color: #9a9da0;
		margin-right: 3px;
	}

	.gs-news-cat-title{
		font-size: 0.95rem;
		font-weight: bold;
		border-bottom: 1px solid #e5e5e5;
		padding: 10px 0;
		margin: 0 0 12px;
		position: relative;

		@include media(tablet){
			display: none;
		}

		.icon {
			font-size: 14px;
			position: absolute;
			top: 50%;
			right: 0;
			margin: -7px 0 0;
			cursor: pointer;
			transition: all 0.3s;
		}
	}

	.gs-cat-items{
		display: block;
		cursor: pointer;
		padding-left: 12px;
		font-size: 0.9rem;

		&.active{
			>a{
				color: var(--accentColor);
			}

			&:before{
				display: block;
			}
		}

		&.mobile-all-items-link{
			display: none;

			@include media(tablet){
				display: block;
			}
		}

		&:before {
			content: "\e909";
			position: absolute;
			top: 4px;
			left: 0;
			font-family: 'icomoon' !important;
			font-size: 8px;
			display: none;
		}
	}

	.gs-cat-item{
		display: block;
		margin: 0 0 10px;

		@include media(hover){
			transition: all .3s;

			&:hover{
				color: var(--accentColor);
			}
		}

		&.active{
			color: var(--accentColor);
		}
	}

	.gs-sub-cat-items{
		padding: 0 8px;
	}

	.gs-cats-wrapper{
		@include media(tablet){
			@include absolute(top calc(100% + 2px) right 0);
			@include size(100%, auto);
			padding: 15px 15px 5px 15px;
			border: 1px solid #e5e5e5;
			background-color: #fff;
			display: none;
		}
	}

}