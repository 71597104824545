.gs-add-cart-form{
	display: inline-block;
	vertical-align: top;
	width: 50% !important;
	padding: 15px 35px 80px 35px;
	font-size: 14px;
	z-index: 1;
  	min-height: calc(100vh - 100px);

  	@include media(tablet){
		display: block;
		width: 100% !important;
		padding: 0 15px;
		min-height: inherit;
		position: relative !important;
		top: 0 !important;
		bottom: auto !important;
		margin-top: 25px;
	}

  	.gs-bottom-container{
		@include absolute(bottom 0 left 0);
		font-size: 14px;
		padding: 20px 35px;
		width: 100%;

		@include media(tablet){
			@include relative();
			margin-top: 25px;
			padding: 0;
		}

		.gs-share-area{
			margin-top: 0;
		}


	}

	.gs-video-thumb{
		@include absolute(top 0 right 100%);
		@include size(50px, 40px);
		font-size: 28px;
		text-align: center;
		cursor: pointer;
		@include transition(all .5s);

		@include media(tablet){
			top:auto;
			bottom: 100%;
			right: auto;
			left: 0;
			margin-bottom:25px;
		}
		
		span{
			@include relative(top 6px);
			display: inline-block;
		}

		@include media(hover){
			&:hover{
				width: 70px;
			}
		}
	}
}

.gs-cart-form{
	width: 100%;
	max-width: 540px;

	@include media(tablet){
		max-width: 100%;
	}

	.gs-cart-preload{
		@include absolute(top 0 left 0);
		@include size(100%, 100%);
		z-index: 6;
		border:2px solid;
		text-align: center;
		visibility: hidden;
		opacity: 0;

		&.active{
			visibility: visible;
			opacity: 1;
		}

		span{
			@include absolute(top 50% left 0);
			@include size(100%, auto);
			display: block;
			margin-top: -10px;
			text-align: center;
			font-size: 20px;
			animation: blink .5s infinite;
		}

		strong{
			@include absolute(top 50% left 50%);
			@include size(22px, 22px);
			margin: -11px 0 0 -11px; 
			z-index: 1;
			visibility: hidden;
			opacity: 0;
			background: #fff;
			backface-visibility: hidden;

			em{
				font-size: 22px;
				font-style: normal;
				backface-visibility: hidden;
			}
		}
	}

	.gs-pr-info{
		margin-top: 25px;

		.gs-pr-price{
			display: inline-block;
			vertical-align: middle;
			margin-right: 10px;

			span{
				text-decoration: line-through;
				font-size: 14px;
				white-space: nowrap;
				margin-right: 5px;
			}

			strong{
				font-weight: normal;
				font-size: 18px;
				white-space: nowrap;
			}

		}

		.gs-tags{
			@include relative(top 0 left 0);
			display: inline-block;
			vertical-align: middle;
			@include size(auto, auto);

			em{
				@include relative();
				display: inline-block;
				vertical-align: middle;
			}
		}
	}

	.gs-item-title{
		margin-top: 20px;

		h1{
			font-size: 24px;
			font-family: $font-main;
		}

	}

	.item-info{

		ul{
			@extend .gs-list;
			font-size: 12px;

			li{
				display: inline-block;
				vertical-align: top;
				margin: 10px 10px 0 0;
			}

			strong{
				font-weight: normal;
			}
		}
	}

	.gs-item-txt{
		margin-top: 25px;
		font-size: 12.5px;
		line-height: 1.2;
	}

	.gs-selects-container{
		margin-top: 25px;

		@include media(mobile-portrait){
			margin-top: 15px;
		}
	}

	.gs-select-wrap{
		@include size(100%, auto);
		max-width: 130px;
		display: inline-block;
		vertical-align: middle;
		margin: 5px 2px 0 0;

		@include media(mobile-portrait){
			max-width: 100%;
			margin: 5px 0;
		}
		
		.gs-select-tick{
			@include absolute(bottom 0 right 0);
			@include size(25px, 25px);
			z-index: 5;
			text-align: center;
			pointer-events:none;
			border-left: none;

			.gs-select-icon{
				@include absolute(left 0 top 50%);
				font-size: 9px;
				display: block;
				text-align: center;
				@include relative(top 50% left 0);
				@include transformY(-50%);
			}
		}

		select{
			@include size(100%, 25px);
			padding: 0 2px;
			font-size: 12px;
			border: 1px solid;
			box-shadow: none;
			outline: none;
			border-radius: 0;

			&:focus{
				outline: none;
			}
		}
	}

	.gs-item-sum{
		margin-top: 25px;
		display: block;

		.gs-update-sum{

			label{
				display: inline-block;
				vertical-align: middle;
				font-size: 12px;
			}

			input{
				@include size(35px, 15px);
				padding: 0 5px;
				display: inline-block;
				vertical-align: middle;
				border: 0;
				font-size: 12px;
			}
			

			a{
				display: inline-block;
				vertical-align: middle;
				font-size: 15px;
				margin-right: 5px;
			}

		}
	}

	.gs-submit-cart{
		display: block;
		overflow: hidden;
		max-width: 280px;
		margin-top: 25px;

		@include media(tablet){
			max-width: 100%;
		}

		a.gs-sbm-cart{
			display: block;
			text-align: center;
		}

	}

	.fb-pr-like{
		margin-top: 25px;
	}

	.gs-dl-btn{
		margin-top: 15px;
	}
}

.gs-cart-error{
	@include absolute(bottom 30px left 5px);
	@include size(auto, auto);
	z-index: 1;
	padding: 3px 5px;
	font-size: 12px;
	display: none;

	span{
		display: block;
		text-align: left;
		line-height: normal;

		em{
			display: inline-block;
			font-style: normal;
			font-size: 12px;
			margin-right: 2px;

			error{
				display: inline-block;
				vertical-align: middle;
			}
		}
	}
}

.gs-fast-order.gs-overwrite{
	text-align: right;
	border-radius: 0;
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 0;
	@include absolute(top 25px right 35px);

	@include media(tablet){
		@include relative(top 0 right 0);
		margin-top: 25px;
		text-align: left;
		display: inline-block;
	}

	&.active{
		&:before, input.gs-text-fld, .fast-order-sbm, .gs-order-error, .gs-gdpr-checkbox{
			visibility: visible;
			opacity: 1;
		}

		strong{
			&:before{
				opacity: 1;
			}

			&:after{
				opacity: 0;
			}
		}
	}

	&:before{
		content: '';
		@include absolute(bottom 100% right 0);
		background: #fff;
		border: 1px solid;
		@include size(232px, 160px);
		margin-bottom: 5px;
		@include transition(all .3s $easeInOutQuad);
		visibility: hidden;
	    opacity: 0;

	    @include media(tablet){
	    	right: auto;
	    	left: 0;
	    }
	}

	form{
		@include relative();
		@include size(100%, auto);
	}

	strong{
		font-size: 14px;
		font-weight: normal;
		text-align: left;
		margin: 0;
		padding-right: 20px;
		cursor: pointer;
		display: inline-block;
		position: relative;
		

		@include media(hover){
			&:hover:after{
				right:-5px
			}
		}

		&:before{
			content: "\e907";
			font-family: 'icomoon' !important;
			display: inline-block;
			font-size: 14px;
			font-weight: bold;
			@include absolute(top 50% right 0);
			margin-top: -7px;
			@include transition(all .3s $easeInOutQuad);
			opacity: 0;
		}

		&:after{
			content: "\e909";
			font-family: 'icomoon' !important;
			display: inline-block;
			font-size: 12.99px;
			@include absolute(top 50% right 0);
			margin-top: -7px;
			@include transition(all .3s $easeInOutQuad);
		}

		em{
			display: inline-block;
			vertical-align: baseline;
			font-size: 14px;
			font-style: normal;
			text-transform: lowercase;
		}
	}

	input.gs-text-fld{
		@include size(200px, 30px);
		border-radius: 0;
		display: inline-block;
		vertical-align: top;
		margin: 0;
		font-size: 12px;
		padding: 0 10px;
		border-radius: 0;
		font-style: italic;
		@include absolute(bottom 100% right 15px);
		margin-bottom: 115px;
		@include transition(all .3s $easeInOutQuad);
		visibility: hidden;
	    opacity: 0;

	    @include media(tablet){
	    	right: auto;
	    	left: 15px;
	    }

	}

	.gs-gdpr-checkbox{
		@include absolute(bottom 100% left 15px);
		width: 200px;
		margin-bottom: 60px;
		font-size: 12px;
		@include transition(all .3s $easeInOutQuad);
		visibility: hidden;
	    opacity: 0;

		.gs-gdpr-text{
			padding-left: 15px;
		}
	}

	.fast-order-sbm{
	    @include relative();
	    display: inline-block;
	    vertical-align: top;
	    border: 2px solid;
	    margin: 0;
	    padding: 6px 16px;
		font-size: 12.99px;
	    border-radius: 0;
	    width: auto;
	    @include absolute(bottom 100% right 15px);
	    margin-bottom: 20px;
	    @include transition(all .3s $easeInOutQuad);
	    visibility: hidden;
	    opacity: 0;

	    @include media(tablet){
	    	right: auto;
	    	left: 15px;
	    }
	}

	.gs-order-error{
		font-size: 14px;
		@include absolute(bottom 100% left 0);
		@include size(100%, auto);
	    margin-bottom: 75px;
	    text-align: center;
	    visibility: hidden;
	    opacity: 0;
	    @include transition(all .3s $easeInOutQuad);
	}
}