.gs-tabs-view{
	@include relative();
	@include size(100%, auto);

}

.gs-tabs-body{
	border-bottom: 1px solid;;

	.gs-tab-content{
		@include relative();

		&.active{
			.gs-tab-wrap{
				display: block;
			}
		}

	}

	.gs-tab-wrap{
		padding: 15px 10px;
		display: none;
		max-width: 100%;
		border-top: 1px solid;
		font-size: 12.99px;

		.gs-tab-subtitle{
			margin-bottom: 20px;
		}
	}

	.gs-mobile-title{
		width: 100%;
		display: block;
		padding: 15px 0;
		border-top: 1px solid;
		font-weight: normal;
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		position: relative;
		cursor: pointer;

		.icon{
			@include absolute(right 15px top 50%);
			margin-top: -5px;
			@include transition(all .5s);
			font-size: 10px;
		}

		&.active{
			.icon{
				@include rotate(-180deg);
			}
		}

	}

	.gs-tab-table{
		width: 100%;

		.gs-tab-row{
			padding: 5px 0;
		}

		.gs-tab-cell{
			display: inline-block;
			vertical-align: middle;
			margin-right: 5px;
		}
	}

}