/* Main Banner Slider */
.gs-main-banner{
	@include relative();
	@include size(100%, 0);
	padding-top: 35.996%;
	min-height: 420px;
	overflow:hidden;
}

.gs-top-slider{
	@include absolute(top 0 left 0);
	@include size(100%, 100%);
	overflow:hidden;

	.gs-slides{
		@include relative();
		overflow: hidden;
		pointer-events: none;

		&.active{
			pointer-events: inherit;

			h2{
				@include transformX(0px);
				opacity: 1;
			}

			.gs-read-more-bnr{
				opacity: 1;
				@include transformX(0px);
			}
		}

		&:nth-child(even){

			&.active{

				.gs-slider-text{

					h2{
						@include transformX(0px);
					}

					.gs-read-more-bnr{
						@include transformX(0px);
					}

				}

			}

			.gs-slider-text{
				text-align: left;

				.gs-text-container{
					vertical-align: bottom;

					@include media(mobile){
						vertical-align: top;
					}
				}

				.gs-text-wrap{
					padding: 0 35px 0 0;
				}

				h2{
					@include transformX(-50px);
				}

				.gs-read-more-bnr{
					@include transformX(-50px);
				}
			}
		}
	}

	.gs-slider-text{
		@include size(100%, 100%);
		max-width: 1210px;
		padding: 0 15px;
		margin: 0 auto;
		text-align: right;
		font-size: 0;

		@include media(laptop){
			padding: 0 50px;
		}

		@include media(mobile){
			padding: 0 15px;
		}

		&:before{
			content: '';
			display: inline-block;
			vertical-align: middle;
			@include size(0, 100%);
		}
	

		.gs-text-container{
			display: inline-block;
			vertical-align: top;
			width:50%;
			padding: 8% 0;

			@include media(laptop){
				width: calc(50% + 100px);
				padding: 50px 0 60px;
			}

			@include media(mobile){
				width: 100%;
			}
		}

		.gs-text-wrap{
			text-align: left;
			padding: 0 0 0 35px;

			@include media(mobile){
				padding: 0;
				color: #fff;
			}
		}

		h2{
			font-size: 60px;
			font-weight: bold;
			backface-visibility: hidden;
			opacity:0;
			@include transition(all .5s $easeInOutSine);
			@include transformX(50px);

			@include media(tablet){
				font-size: 48px;
			}
		}
		
		.gs-read-more-bnr{
			margin-top: 15px;
			@include transition(all .5s $easeInOutSine);
			@include transformX(50px);
			@include delay(.2s);
			opacity:0;
		}

	}

	.gs-nav-dots{
		@include absolute(bottom 5% left 0);
		@include size(100%, auto);
		text-align: center;
		z-index: 1;

		@include media(mobile){
			color: #fff;
		}

		.swiper-pagination-bullet{
			display: inline-block;
			vertical-align: middle;
			position: relative;
			@include size(17px, 17px);
			margin: 0 5px;
			border-radius: 50px;
			backface-visibility: hidden;
			border: 1px solid;
			opacity: 1;

			&:before{
				content: '';
				@include absolute(top 3px left 3px);
				@include size(9px, 9px);
				border-radius: 50px;
				backface-visibility: hidden;
			}
		}
	}

	.gs-nav-arrows{
		@include absolute(top 50% left 0);
		@include size(100%, auto);
		margin-top: -8px;
		z-index: 1;

		@include media(mobile){
			display: none;
		}

		.gs-arrow{
			@include absolute(top 0);
			font-size: 22px;
			cursor: pointer;

			&.gs-prev{
				left:20px;

				@include media(tablet){
					left: 10px;
				}
			}

			&.gs-next{
				right: 20px;

				@include media(tablet){
					right: 10px;
				}
			}
		}
	}

}

.gs-banner-scheme{

	.gs-banners-wrap{
		margin: 0 -15px;
		font-size: 0;

		@include media(tablet){
			margin: 0 -10px;
		}
	}

	.gs-banner-item{
		font-size: 14px;
		display: inline-block;
		vertical-align: top;
		width: 50%;
		padding: 0 15px;

		@include media(tablet){
			padding: 0 10px;
		}

		@include media(mobile){
			width: 100%;
			margin-bottom: 30px;

			&:last-child{
				margin-bottom: 0;
			}
		}

		@include media(hover){
			&:hover{
				.gs-item-image{
					@include transformY(-5px);
				}

				a.gs-read-more{
					text-decoration: underline;
				}
			}
		}

		&.breakthrough-banner{
			padding:0;
			margin: 0;
			width: 100%;
			overflow: hidden;

			@include media(hover){
				&:hover{
					.gs-item-image{
						@include scale(1.02);
					}
				}
			}


			.gs-item-image{
				padding-top: 20%;
				min-height: 150px;
			}

			.gs-text-wrap{
				@include absolute(bottom 0 left 0);
				@include size(100%, auto);
			}

			.gs-bnr-txt{
				max-width: 1210px;
				padding: 0 15px 35px;
				margin: 0 auto;
			}

			.gs-background{
				top: -30%;
				height: 130%;
			}
		}

		&.single-banner{
			width: 100%;
			padding: 0;

			.gs-item-image{
				padding-top: 0;
				height: auto;

				a{
					display: block;
				}

				img{
					max-width: 100%;
				}
			}
		}

	}

	.gs-item-wrapper{
		@include size(100%, 100%);
		overflow: hidden;
	}

	.gs-item-image{
		@include relative();
		@include size(100%, 0);
		overflow: hidden;
		padding-top: 80%;
		@include transition(all .5s $easeInOutSine);
	}

	.gs-background{
		backface-visibility: hidden;
	}

	.gs-text-wrap{
		margin-top: 15px; 

		@include media(mobile){
			margin-top: 10px;
		}

		.gs-bnr-txt{
			overflow: hidden;
		}

		h5{
			font-weight: bold;
		}

		a.gs-read-more{
			margin-top: 10px;
		}
	}

}