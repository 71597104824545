.gmbStdHover{
	@include fixed(top 0 left 0);
	@include size(100%, 100%);
	z-index: 49;
}

.gmbStdMsg{
	@include fixed(top 20% left 50%);
	@include transformX(-50%);
	z-index: 50;
	text-align: center;
	padding: 50px 20px;

	.gmbStdMsgContent{
		max-width: 490px;
		margin: 0 auto;
		text-align: center;
		padding: 30px 50px;

		&:before{
			content: '';
			@include size(27px, 27px);
			background: url("../images/icons/error.png") no-repeat;
			display: block;
		    text-align: center;
		    margin: 0 auto 10px;
		}
	}

	.gmbStdMsgClose{
		border: 2px solid;
		padding: 8px 20px;
	}
}