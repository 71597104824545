.gs-view-more{
	display: inline-block;
	vertical-align: middle;
	border: 1px solid;
	@include transition(.5s all);
	padding: 12px 20px;
	font-size: 12.99px;
	text-transform: uppercase;
	@include relative();

	@include media(mobile){
		padding: 10px 15px;
	}
	
	&.small{
		padding: 8px 12px;
	}

}

.gs-download{
	display: inline-block;

	strong{
		font-weight: normal;
		display: inline-block;
		vertical-align: middle;
	}

	span{
		display: inline-block;
		vertical-align: middle;
		font-size: 18px;
		margin-right: 5px;
	}
}

.gs-read-more{
	@include relative();
	font-size: 12px;
	text-transform: uppercase;
	display: inline-block;
	font-family: $font-heading;
	padding-right: 20px;
	@include transition(all .3s $easeInOutQuad);

	@include media(hover){
		&:hover{
			padding-right: 28px;
		}
	}

	&:after{
		content: "\e909";
		font-family: 'icomoon' !important;
		display: inline-block;
		font-size: 12.99px;
		@include absolute(top 50% right 0);
		margin-top: -7px;
	}
}

.gs-qw-button{
	display: none;
}

.gs-newpay-leasing.gs-overwrite{
	margin: 10px 0 0;
	display: block;
	max-width: 280px;

	@include media(mobile-portrait){
		max-width: 100%;
	}

	.new-pay-btn{
		display: block;
		border-radius: 0;
		margin: 10px 0 0;
		padding: 4px 16px;
	}

}

.gs-product-box__buttons{
	display: none;
}

.gs-add-cart-form{
	.gs-product-box__buttons.overwrite{
		vertical-align: middle;

		.gs-compare-product-button{
			font-size: 12px;
			color: #000;
		}

		.gs-compare-button__icon{
			@include size(16px, 16px);
			opacity: .5;
		}
	}
}

.gs-floated-grid, .gs-products-slider{

	.gs-product-box__buttons.overwrite{
		@include absolute(bottom 0 left 0);
		padding: 10px 5px;
		justify-content: space-between;
		z-index: 1;
		width: 100%;

		.gs-compare-product-button{
			width: 35px;
			height: 21px;
			background-color: #fff;
			padding: 0;
			justify-content: center;
			align-items: center;
			pointer-events: all;
			position: relative;

			&:after{
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				background-color: #000;
				opacity: 0.052;
			}
		}

		.gs-compare-button__text{
			display: none;
		}

		.gs-compare-button__icon{
			@include size(16px, 16px);
			margin-right: 0;
		}
	}

}




