.gs-sidebar{
	@include size(100%, auto);
	@include absolute(top 100% right 0);
	margin-top: 5px;
	overflow: hidden;
	padding: 0 15px;
	z-index: 1;
	visibility: hidden;
	opacity: 0;
	@include transition(all .3s);

	&.active{
		visibility: visible;
		opacity: 1;
	}
}

.gs-sidebar-filters{
	@include size(100%, auto);
	max-width: 670px;
	margin: 0 auto;
	text-align: left;
}

.gs-clear-filters{
	@include absolute(top 0 left 100%);
	@include size(25%, auto);
	text-align: right;

	@include media(mobile){
		@include relative(top 0 left 0);
		width: 100%;
		text-align: left;
		margin-bottom: 15px;
	}

	.gs-clear-btn{
		font-size: 12px;
		text-decoration: underline;
	}
}

.gs-apply-filters{
	margin-top: 25px;
	text-transform: uppercase;
}

.gs-cat-form{
	position: relative;

	&.loading{
		pointer-events:none;
	}
}

.gs-cat-filters{
	font-size: 0;
	margin: 0 auto;
	padding: 25px 23% 35px 25px;
	border:1px solid;

	@include media(mobile){
		padding: 15px;
	}
}


.gs-cat-title{
	margin-bottom: 5px;
	font-size: 14px;
}	


.gs-category{
	margin-bottom: 20px;
	display: inline-block;
	width: 33.3%;
	vertical-align: top;
	padding: 0 20px 0 0;

	@include media(mobile){
		width: 50%;
	}
}

.gs-cat-labels{

	.gs-labels-wrap{
		width: 100%;
		height: 100%;
		text-align: left;
	}

	a{
		display: block;
	}

	strong{
		display: block;
		padding: 0 5px;
		margin-bottom: 5px;
	}

	label{
		display: block;
    	padding: 6px 0 0 20px;
		cursor: pointer;
		font-size: 12.99px;

		span{
			@include absolute(top 6px left 0);
			@include size(12px, 12px);
			border: 1px solid;

			&:after{
				content: '';
				@include absolute(top 2px left 2px);
				@include size(6px, 6px);
				display: none;
			}

		}

		&.active{
			span:after{
				display: block;
			}
		}

		&.no-products {
			pointer-events: none;
			opacity: .8;
			text-decoration: line-through;
		}
	}

	input{
		display: none;
	}
}


.mCustomScrollbar{ 
	-ms-touch-action: pinch-zoom; 
	touch-action: pinch-zoom; 
}
.mCustomScrollbar.mCS_no_scrollbar, .mCustomScrollbar.mCS_touch_action{ 
	-ms-touch-action: auto; 
	touch-action: auto; 
}

.mCustomScrollBox{
	position: relative;
	overflow: hidden;
	height: 100%;
	max-width: 100%;
	outline: none;
	direction: ltr;
	backface-visibility: hidden;
}

.mCSB_container{
	overflow: hidden;
	width: auto;
	height: auto;
}

.mCSB_inside > .mCSB_container{ 
	margin-right: 25px; 
}

.mCSB_container.mCS_no_scrollbar_y.mCS_y_hidden{ 
	margin-right: 0; 
}

.mCSB_scrollTools{
	position: absolute;
	width: 6px;
	height: auto;
	left: auto;
	top: 0;
	right: 0;
	bottom: 0;
}

.mCSB_outside + .mCSB_scrollTools{ 
	right: -26px; 
}

.mCSB_scrollTools .mCSB_draggerContainer{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0; 
	height: auto;
	width: 6px;
}

.mCSB_scrollTools a + .mCSB_draggerContainer{ 
	margin: 20px 0; 
}

.mCSB_scrollTools .mCSB_draggerRail{
	width: 1px;
	height: 100%;
	margin: 0 auto;
	border-radius: 16px; 
}

.mCSB_scrollTools .mCSB_dragger{
	cursor: pointer;
	width: 100%;
	height: 30px; 
	z-index: 1;
}

.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar{
	position: relative;
	width: 4px;
	height: 100%;
	margin: 0 auto;
	text-align: center;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded .mCSB_dragger_bar, 
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_dragger .mCSB_dragger_bar{ 
	width: 12px;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger.mCSB_dragger_onDrag_expanded + .mCSB_draggerRail, 
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerContainer:hover .mCSB_draggerRail{ 
	width: 8px; 
}

.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown{
	display: block;
	position: absolute;
	height: 20px;
	width: 100%;
	overflow: hidden;
	margin: 0 auto;
	cursor: pointer;
}

.mCSB_scrollTools .mCSB_buttonDown{
 	bottom: 0; 
}


.mCSB_scrollTools, 
.mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar, 
.mCSB_scrollTools .mCSB_buttonUp,
.mCSB_scrollTools .mCSB_buttonDown,
.mCSB_scrollTools .mCSB_buttonLeft,
.mCSB_scrollTools .mCSB_buttonRight{
	-webkit-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-moz-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	-o-transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
	transition: opacity .2s ease-in-out, background-color .2s ease-in-out;
}

.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar,
.mCSB_scrollTools_vertical.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_dragger_bar, 
.mCSB_scrollTools_horizontal.mCSB_scrollTools_onDrag_expand .mCSB_draggerRail{
		-webkit-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
		margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
		margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
		opacity .2s ease-in-out, background-color .2s ease-in-out; 
		-moz-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
		margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
		margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
		opacity .2s ease-in-out, background-color .2s ease-in-out; 
		-o-transition: width .2s ease-out .2s, height .2s ease-out .2s, 
		margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
		margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
		opacity .2s ease-in-out, background-color .2s ease-in-out; 
		transition: width .2s ease-out .2s, height .2s ease-out .2s, 
		margin-left .2s ease-out .2s, margin-right .2s ease-out .2s, 
		margin-top .2s ease-out .2s, margin-bottom .2s ease-out .2s,
		opacity .2s ease-in-out, background-color .2s ease-in-out; 
}

/* Ui Slider */
.noUi-target,
.noUi-target * {
-webkit-touch-callout: none;
-webkit-user-select: none;
-ms-touch-action: none;
	touch-action: none;
-ms-user-select: none;
-moz-user-select: none;
	user-select: none;
-moz-box-sizing: border-box;
	box-sizing: border-box;
}
.noUi-target {
	position: relative;
	direction: ltr;
}
.noUi-base {
	width: 100%;
	height: 100%;
	position: relative;
	z-index: 1; /* Fix 401 */
}
.noUi-origin {
	position: absolute;
	right: 0;
	top: 0;
	left: 0;
	bottom: 0;
}
.noUi-handle {
	position: relative;
	z-index: 1;
}
.noUi-stacking .noUi-handle {
/* This class is applied to the lower origin when
   its values is > 50%. */
	z-index: 10;
}
.noUi-state-tap .noUi-origin {
-webkit-transition: left 0.3s, top 0.3s;
	transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
	cursor: inherit !important;
}

/* Painting and performance;
 * Browsers can paint handles in their own layer.
 */
.noUi-base,
.noUi-handle {
	-webkit-transform: translate3d(0,0,0);
	transform: translate3d(0,0,0);
}

/* Disabled state;
 */
[disabled].noUi-connect,
[disabled] .noUi-connect {
	background: #B8B8B8;
}
[disabled].noUi-origin,
[disabled] .noUi-handle {
	cursor: not-allowed;
}