.gs-footer{
	position: relative;
	overflow: hidden;
	border-top: 1px solid;

	.gs-footer-grid{
		font-size: 0;
		margin: 0 -25px;
		text-align: center;
		display: flex;
		padding: 4% 0 0;

		@include media(tablet-medium){
			display: block;
		}

		@include media(mobile){
			padding: 35px 0 0;
		}
	}

	.gs-footer-col{
		font-size: 18px;
		display: inline-block;
		vertical-align: top;
		width: 25%;
		padding: 10px 25px;
		text-align: left;

		@include media(tablet-medium){
			display: block;
			width: 100%;
		}

		h6{
			margin-bottom: 10px;
			font-weight: bold;
			font-size: 15px;
		}

		nav{
			display: block;
			max-width: 250px;

			@include media(tablet-medium){
				max-width: 100%;
			}

			&.gn-footer-menu{
				max-width: 250px;

				@include media(tablet-medium){
					max-width: 100%;
				}
			}

			a{
				display: block;
				font-size: 12.99px;
				margin: 0 0 5px 0;
				@include transition(all .5s);

				@include media(tablet-medium){
					display: inline-block;
					margin: 0 5px 5px 0;
				}

				@include media(hover){
					&:hover{
						text-decoration: underline;
					}
				}
			}
		}
	}


	.gs-footer-contacts{
		max-width: 180px;

		@include media(tablet-medium){
			max-width: 100%;
		}
		
		ul{
			@extend .gs-list;
		}

		li{
			display: block;
			font-size: 12.99px;
			padding: 0 0 10px 0;
			line-height: 1.3;
			@include transition(all .5s);

			strong{
				font-weight: normal;
			}
		}

	}

	.gs-footer-follow{
		font-size: 0;
		margin: 3% 0 0;
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		&.with-logos{

			.gs-like-box{
				display: block;
				width: 100%;
				margin-top: 10px;
				text-align: left;
			}

			.gs-footer-socials{
				display: inline-block;
				vertical-align: top;
				width: 50%;

				ul{
					display: block;
					width: 100%;

					@include media(tablet-portrait){
						width: 100%;
					}
				}
			}

		}

		.gs-footer-socials{

			.gs-like-box{
				display: inline-block;
				vertical-align: middle;
				width: 50%;
				text-align: right;

				@include media(tablet-portrait){
					width: 100%;
					margin-top: 10px;
					text-align: left;
				}
			}

			ul{
				@extend .gs-list;
				width: 50%;
				display: inline-block;
				vertical-align: top;

				@include media(tablet-portrait){
					width: 100%;
				}
			}

			li{
				display: inline-block;
				vertical-align: top;
				margin: 0 15px 5px 0;
			}


			a{
				display: block;
				font-size: 15px;
				@include transition(all .5s);
			}
		}
	}

	.gs-payment-icon{
		display: inline-flex;
		vertical-align: top;
		width: 50%;
		justify-content: flex-end;
		flex-wrap: wrap;

		@include media(tablet){
			width: 100%;
			justify-content: flex-start;
			margin-top: 20px;
		}

		span{
			display: inline-flex;
			margin: 0 0 5px 8px;
			flex: 0 0 100%;
			max-width: 40px;

			&.gs-payment-paysera{
				max-width: 56px;
			}

			@include media(tablet){
				margin: 0 8px 5px 0;
			}

			img{
				max-width: 100%;
				display: block;
			}
		}
	}

	.gs-footer-hint{
		font-size: 12px;

		p{
			margin-bottom: 10px;
		}
	}

	.gs-footer-bottom{
		border-top: 1px solid;
		margin:3% 0 0;

		@include media(mobile){
			margin: 35px 0 0;
		}
	}

	.gs-copy-right{
		text-align: left;
		padding: 20px 0;

		span{
			@include transition(all .5s);
			font-size: 12px;
			padding: 5px 0;

			@include media(tablet-portrait){
				display: block;
			}

			&:nth-child(2){
				float: right;
			
				@include media(tablet-portrait){
					float: none;
				}
			}
			
			a{
				@include transition(all .5s);

				@include media(hover){
					&:hover{
						opacity: .7;
					}
				}
			}

		}
	}

	.gs-news-letter{
		margin: 35px 0 0;
	}

	.gs-news-letter-title{
		font-size: 14px;
		font-weight: bold;
		text-transform: uppercase;
	}

	.gs-news-letter-sub-title{
		color: #9a9da0;
		font-size: 12px;
		margin: 10px 0 0;
	}

	.gs-news-letter-form{
		margin: 10px 0 0;
		display: block;
		width: 100%;
		position: relative;

		&.loading{
			.gs-news-letter-submit{
				pointer-events: none;
				opacity: .5;
			}
		}
	}

	.gs-letter-box{
		display: flex;
	}

	.gs-news-letter-mail{
		@include size(100%, 36px);
		border: 1px solid #000;
		box-shadow: none;
		background-color: transparent;
		padding: 0 10px;
		font-style: italic;
		font-size: 12px;

		&.error{
			border-color: red;
		}
	}

	.gs-new-letter-terms{
		margin: 15px 0 0;
		display: flex;
		align-items: flex-start;
		font-size: 12px;

		&.error{
			.gs-new-letter-label{
				color: red;
				border-bottom: 1px solid red;
			}
		}
	}

	.gs-new-letter-agree{
		display: inline-block;
		vertical-align: middle;
		margin-right: 5px;
		@include size(16px, 16px);
		background-color: #fff;
		border: 1px solid #d3d3d3;
		margin-top: -3px;
	}

	.gs-new-letter-label{
		display: inline-block;
		vertical-align: middle;
		@include transition(all .5s);
	}

	.gs-news-letter-submit{
		outline: none;
		padding: 1px 25px;
		white-space: nowrap;
	}

	.gs-new-letter-error{
		@include absolute(top -10px right 0);
		background-color: red;
		z-index: 1;
		padding: 3px 10px;
		font-style: italic;
		color: #fff;
		font-size: 11px;
		border-radius: 3px;
		opacity: 0;
		visibility: hidden;
		@include transition(all .3s);

		&.active{
			opacity: 1;
			visibility: visible;
		}
	}

	.gs-new-letter-success{
		@include absolute(bottom 0 left 0);
		@include size(100%, 100%);
		overflow: hidden;
		color: #28a745;
		display: flex;
		align-items: center;
		justify-content: center;
		font-style: italic;
		padding: 0 15px;
		text-align: center;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;
		z-index: 2;
		background-color: #fff;
		@include transition(all .5s);

		&.active{
			opacity: 1;
			visibility: visible;
			pointer-events: all;
		}
	}
}