// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html { 
  box-sizing: border-box;
}
body{
	position: relative;
	width: 100%;

	&.catalog, &.product{
		.gs-breadcrumb{
			text-align: left;
		}
	}
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*, *::before, *::after {
  box-sizing: inherit;
} 

/**
 * Basic styles for links
 */
a {
  color: inherit;
  text-decoration: none;
}

.gs-main-container{
	@include relative();
	@include size(100%, auto);
	padding: 100px 0 0;

	@include media(tablet){
		padding: 75px 0 0;
	}
}

.gs-content-wrap{
	position: relative;
	width: 100%;
	z-index: 1;
}

.gs-section{
	position: relative;
	width: 100%;
}

.gs-section-wrap{
	position: relative;
	width: 100%;
	max-width: 1210px;
	margin: 0 auto;
	padding: 0 15px;
}

.gs-section-title{
	position: relative;
	width: 100%;

	h1{
		font-size: 22px;
	}

	.gs-rtf{
		max-width: 850px;
	}
}

.gs-copy-text{
	position: relative;
	width:100%;

	p{
		margin-bottom: 20px;
		line-height: 1.4em;

		&:last-child{
			margin-bottom: 0;
		}
	}
}

.gs-important{
	a{
		text-decoration: underline;
	}
}

.gs-separator{
	position: relative;
	width: 100%;
	padding: 2% 0;

	@include media(mobile){
		padding: 4% 0;
	}
	
	&.smaller{
		padding: 5px 0;
	}

	&.small{
		padding: 10px 0;
	}

	&.medium{
		padding: 12.5px 0;

		@include media(mobile){
			padding: 10px 0;
		}
	}
}

.gs-share-area{
	margin-top: 15px;

	ul{
		@extend .gs-list;
	}

	li{
		margin: 5px 10px 5px 0;
		display: inline-block;
		vertical-align: middle;
	}

	a{
		font-size: 14px;
		text-decoration: none;
	}

	span{
		display: inline-block;
		vertical-align: middle;
	}

	em{
		display: inline-block;
		vertical-align: middle;
		font-style: normal;
		margin-left: 2px;
		font-size: 12px;
	}
}

.gs-pattern{
	@include absolute(top 0 left 0);
	@include size(100%, 100%);
}

.gs-bg-pattern{
	@include absolute(top 0 left 0);
	@include size(100%, 100%);
}

/* Background Image Cover*/
.gs-background{
	@include size(100%, 100%);
	@include absolute(top 0 left 0);
	background-size: cover;
	background-repeat:no-repeat;
	background-position: 50% 50%;

	&.gs-fixed-bg{
		background-attachment: fixed;

		@include media(tablet){
			background-attachment: inherit;
		}
	}
}

.gs-preloader{
	width: 100%;
	height: 100%;
	top:0;
	left:0;
	position: fixed;
	z-index: 100;
}

.gs-prel-holder{
	@include relative(top 50%);
	@include size(30px, 30px);
	margin: -15px auto 0;
	z-index: 2;
	text-align: center;

	span{
		@include absolute(top 50% left 0);
		width: 100%;
		text-align: center;
		display: block;
		font-size: 12px;
		margin-top: -8px;
	}
}

.gs-spinner{
  animation: rotator 1s linear infinite;

  .gs-path{
	  stroke-dasharray: 185;
	  stroke-dashoffset: 0;
	  transform-origin: center;
	  animation: dash 1s ease-in-out infinite, 
	}
}

.gs-breadcrumb{
	@include size(100%, auto);
	z-index: 2;
	padding: 20px 0 35px;

	ul{
		@extend .gs-list;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;

		li{
			display: inline-block;
			font-size: 11px;
		}

		a{
			display: block;

			em{
				@include transition(all .5s);
				@include relative();
				display: inline-block;
				text-decoration: underline;

				@include media(hover){
					&:hover{
						text-decoration: none;
					}
				}
			}
		}

		span{
			display: block;
		}

		sub{
			bottom: 0;
		    line-height: normal;
		    font-size: 14px;
		    margin: 0 5px;
		    display: inline-block;
		}
	}
}

.gs-rtf{
	line-height: 1.3;

	a{
		text-decoration: underline;
		
		@include media(hover){
			&:hover{
				text-decoration: none;
			}
		}
	}

	h1, h2, h3, h4, h5, h6 {
    	line-height: 1.1;
	}
	
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 26px;
	}

	h3 {
		font-size: 24px;
	}

	h4 {
		font-size: 22px;
	}

	h5 {
		font-size: 20px;
	}

	h6 {
		font-size: 18px;
	}

	iframe{
		max-width: 100%;
		border:none;
	}

	img{
		max-width: 100%;
		height: auto !important;
	}

}

/* Custom Scroll */
.scrollable {
  	position: relative;

  	&:focus{
		outline: 0;
  	}

  	.viewport {
	  	position: relative;
	  	overflow: hidden;

	  	.overview {
		  @include absolute(top 0 left 0);
		  @include size(100%, auto);
		  padding-right: 30px;
		}
	}

	.scroll-bar{
	  display: none;

	  	&.vertical {
			  @include absolute(top 0 right 10px);
			  @include size(1px, 100%);
			  height: 100%;
			  z-index: 5;

			  	.thumb {
					  width: 10px;
					  left: -5px;
					  min-height: 10px;
				}
		}

		&.horizontal {
		  position: relative;
		  width: 100%;

			.thumb {
				  height: 100%;
				  min-width: 10px;
				  left: 0;
			}

		}

		.thumb {
		  @include absolute();
		}
	}

}

.not-selectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gs-preview{
	@include fixed(bottom 0 left 0);
	@include size(100%, auto);
	z-index: 100;
}


/* Mess From Other Templates */
.quickView-prdPopIn{
	width:100% !important;
	max-width: 450px;
}
.quickView-content{
	width:auto;
	max-width: 100%;
}

.quickView-prdPopIn .quickView-popInBody{
	width: 100% !important;
}

.quickView-order h4{
	height: 33px !important;
}

.quickView-prdPopIn .quickView-closeFader{
	left: 100% !important;
}

/* Qiuck View Pop Up Custom Styles */

.gs-qv-pop-up.gs-qv-overwrite{

	.gs-qv-cart-loading{
		visibility: hidden;
		opacity: 0;
		display: block;
		padding-left: 25px;
		border: 2px solid;
		height: 42px;
		width: calc(100% - 25px);
		top:auto;
		bottom:0;
		left:25px;
		overflow: hidden;

		&.loading{
			visibility: visible;
			opacity: 1;
		}

		&.success{
			span{
				visibility: hidden;
				opacity: 0;
			}

			strong{
				visibility: visible;
				opacity: 1;
				@include transformY(-50%);
			}
		}

		span{
			@extend .icon-bag;
			font-family: 'icomoon' !important;
			@include size(100%, 42px);
			margin: 0;
			top: 0;
			font-size: 22px;
			line-height: 1;
			text-align: center;
			@include transition(all .3s $easeInOutSine);
			@include absolute(top 0 left 0);


			&:before{
				@include relative(top 6px);
				@include animation(0s, .5s, blink, infinite);
			}
		}

		strong{
			@include size(100%, auto);
			@include absolute(top 50% left 0);
			padding: 0 5px;
			margin: 0;
			visibility: hidden;
			opacity: 0;
			font-size: 12px;
			font-style: normal;
			font-weight: normal;
			@include transition(all .3s $easeInOutSine);
			@include delay(.5s);

		}

	}

}



