// -----------------------------------------------------------------------------
// This file contains CSS helper classes.
// -----------------------------------------------------------------------------

/**
 * Clear inner floats
 */
 .clearfix{
  z-index: -1;
}
.clearfix::after {
  clear: both;
  content: '';
  display: table;
}

select, input, textarea{
  font-family: inherit;
  color:inherit;
  resize:none;
  outline:none;
  border:none;
}
header, nav, div, footer, span, ul, li, label, section, input, fieldset, textarea{
  position:relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing:border-box;
  box-sizing:border-box;
}

.gs-slides{
   -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: transform, opacity;
}


/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */

.gs-container {
  max-width: 100%; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: 20px; /* 3 */
  padding-right: 20px; /* 3 */
  width: 100%; /* 1 */
 }

/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0; /* 1 */
  text-indent: 101%;
  white-space: nowrap;
}

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden { 
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.visibility-hidden{
  visibility: hidden;
  opacity:0;
}

.visibility-visible{
  visibility: visible;
  opacity:1;
}

.full-content{
  width: 100%;
  height: 100%;
}

.fl-right{
  float: right;
}

.gs-list{
  list-style: none; 
  list-style-image: none; 
  margin: 0;
  padding: 0; 
}

.center-align{
  text-align: center;
}

.vert-center{
  top:50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
}



