.gs-grid-inline-container{
    display: inline;

    &.invisible{

        .gs-item-image{
            img{
                display: none;
            }
        }
    }
}

.gs-load-more{
    display: none;
    text-align: center;
    margin-bottom: 30px;

    .gs-view-more{
        margin-top: 25px;
        padding: 11px 42px;

        &.loading{
            pointer-events: none;

            .loading-state{
                display: flex;
            }
        }
    }

    .loading-state{
        @include absolute(top 0 left 0);
        @include size(100%, 100%);
        display: none;
        align-items: center;
        justify-content: center;
        z-index: 2;

        &:after{
            content: '';
            border: 2px solid transparent;
            border-radius: 50%;
            width: 24px;
            height: 24px;
            border-top-color: #fff;
            border-left-color: #fff;
            animation: spin 1s linear infinite;
            display: block;
        }
    }
}

.__gs-catalog-products-grid{

    &.active{
        opacity: 1;
        visibility: visible;
    }
}

.gs-pagination{
    text-align: right;

    &.active{
        display: block;
    }

    &.static{
        pointer-events: none;
    }

    .gs-pagination-nav{
        display: flex;
        justify-content: flex-end;
        margin-top: 40px;
    }

    .gs-paging-space{
        font-size: 14px;
        margin: 0 0 5px 5px;
        font-size: 14px;
        margin: 0 0 5px 5px;
        align-self: center;
    }

    a{
        font-size: 14px;
        margin: 0 0 5px 5px;
        @include size(24px, 24px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        @include transition(all .5s);

        &.gs-page-next{
            font-size: 0;

            &:after{
                content: "\e909";
                font-size: 14px;
                font-family: 'icomoon', sans-serif !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        &.gs-page-prev{
            font-size: 0;

            &:after{
                content: "\e90d";
                font-size: 14px;
                font-family: 'icomoon', sans-serif !important;
                speak: none;
                font-style: normal;
                font-weight: 400;
                font-variant: normal;
                text-transform: none;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }
    }
}

.gs-load-page-preloader{
    @include relative();
    text-align: center;
    display: none;

    &.active{
        display: block;
    }

    &.gs-load-page-preloader-top{
        margin: 0 0 30px;
    }

    .gs-loader{
        border: 2px solid;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        margin: 0 auto;
        -webkit-animation: spin 1s linear infinite; /* Safari */
        animation: spin 1s linear infinite;
    }
}

.__gs-back-top-btn{
    display: none;
    pointer-events: all;
    outline: none;
    box-shadow: none;

    &:focus{
        outline: none;
        box-shadow: none;
    }
}

/* Spinner Animation */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}