.gs-faq{
	@include relative();

	.gs-faq-text{
		padding: 20px 0;
		border-top: 1px solid;
	}

	.gs-faq-question{
		max-width: 80%;

		h2{
			font-size: 19px;
			font-family: $font-main;
		}
	}
}

.gs-faq-hint{
	padding: 40px 0 0;
	border-top: 1px solid;
	background: #fff;
	font-size: 14px;

	a{
		text-decoration: underline;
	}
}