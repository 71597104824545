body.catalog-details{
	.gs-anim-block{
		.gs-elem{
			visibility: visible;
			opacity: 1;
			@include transformY(0);
		}
	}
}

body.catalog-search{
	.gs-cat-filter{
		display: none;
	}
}

.gs-top-catalogue{
	z-index: 2;
}

.gs-catalog-title{
	@include size(50%, auto);
	display: inline-block;
	vertical-align: top;

	@include media(tablet-portrait){
		width: 100%;
	}
}


.gs-cat-filter{
	@include absolute(top 0 right 0);
	@include size(50%, auto);
	z-index: 2;
	text-align: right;
	padding-right: 15px;

	@include media(tablet-portrait){
		@include relative;
		padding-right: 0;
		margin-top: 20px;
		width: 100%;
	}

	.gs-filter-btn{
		display: inline-block;
		vertical-align: top;
		margin-right: 35px;
		padding-left: 23px;

		button{
			background: transparent;
			border:0;
			outline: none;
		}

		@include media(tablet-portrait){
			float: left;
		}

		&.active{
			.gs-ham-btn em{
				&.c-1{
					top:4px;
					@include rotate(45deg);
				}

				&.c-2{
					visibility: hidden;
					opacity: 0;
				}

				&.c-3{
					top:4px;
					@include rotate(-45deg);
				}
			}
		}
	}

	.gs-ham-btn{
		@include absolute(top 50% left 0);
		@include size(15px, 10px);
		margin-top: -5px;

		em{
			display: block;
			position: absolute;
			@include size(100%, 1px);
			@include transition(.3s all);

			&.c-1{
				top:0;
			}

			&.c-2{
				top:4px;
			}

			&.c-3{
				top:8px;
			}
		}
	}

	form{
		display: inline-block;
		vertical-align: top;
		text-align: left;
	}

	.gs-select-head{
		@include relative();
		cursor: pointer;
		padding-right: 15px;

		strong{
			display: inline-block;
			vertical-align: top;
			font-weight: normal;
			padding-right: 5px;

			@include media(mobile-small){
				display: none;
			}
		}

		span{
			white-space: nowrap;
			display: inline-block;
			vertical-align: top;
			max-width: 145px;
			padding-right: 10px;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		em{
			@include absolute(top 50% right 0);
			font-style: normal;
			font-size: 8px;
			font-weight: bold;
			margin-top: -4px;
		}
		
	}

	.gs-select-body{
		@include absolute(top 100% right 0);
		@include size(200px, auto);
		margin-top: 10px;
		border: 1px solid;
		overflow: hidden;
		display: none;

		.gs-fake-option{
			@include relative();

			label{
				font-size: 12px;
				display: block;
				padding: 10px;
				cursor: pointer;
				@include transition(all .5s);
			}

			input{
				display: none;
			}
		}

	}
}

.gs-products-container{
	margin-top: 25px;
}

.gs-grid-inline-container{
	display: inline;

	&.invisible{

		.gs-item-image{
			img{
				display: none;
			}
		}
	}
}


.filter-preloader{
	@include fixed(top 0 left 0);
	@include size(100%, 100%);
	background-color: rgba(255,255,255,.4);
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	z-index: -1;
	@include transition(all .5s);

	.gs-spinner{
		display: none;
	}

	&.active{
		z-index: 100;
		visibility: visible;
		opacity: 1;

		.gs-spinner{
			display: block;
		}
	}
}

.gs-fw-banner{
	width:100%;

	img{
		max-width: 100%;
	}
}

.gs-no-items{
	text-align: center;
	padding: 85px 0;
	background: #fff;

	h5{
		font-size: 19px;
	}
}
