/// Regular font family
/// @type List
$font-main: 'Arimo', sans-serif;
$font-heading: 'FreeSans', sans-serif;
$font-accent: 'Arimo', display;


body {
  @include font-weight(normal);
  font-size: 14px;
  font-family: $font-main;
  line-height: 1.1;
}

h1, h2, h3, h4, h5, h6{
	@include font-weight(normal);
	color:inherit;
	padding: 0;
    margin: 0;
	font-size: 14px;
	font-family: $font-heading;
	line-height: 1;
	-webkit-box-sizing: border-box;
	-moz-box-sizing:border-box;
	box-sizing:border-box;
}

h1{
	font-size: 53px;
}
h2{
	font-size: 41px;
}
h3{
	font-size: 31px;
}
h4{
	font-size: 26px;
}
h5{
	font-size: 22px;
}
h6{
	font-size: 17px;
}


