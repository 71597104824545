.gs-item-detail-wrap{
	font-size: 0;

	.gs-item-detail-images{
		display: inline-block;
		vertical-align: top;
		width: 50%;
		z-index: 1;

		@include media(tablet){
			display: block;
			width: 100%;
		}
	}

	.gs-detail-gallery{
		position: relative;
		width: 100%;
	}

	.gs-gallery-wrap{
		@include media(tablet){
			height: calc(100vh - 75px);
		}

		@include media(tablet-portrait){
			height: auto;
			padding-top: 120%;
		}
	}

	.gs-image-slide{
		@include relative();
		@include size(100%, 0);
		height: calc(100vh - 100px);
		margin-bottom: 15px;

		@include media(tablet){
			@include absolute(top 0 left 0);
			@include size(100%, 100%);
			visibility: hidden;
			opacity: 0;
			@include transition(all .3s);

			&.active{
				visibility: visible;
				opacity: 1;
				z-index: 1;
			}
		}

		.gs-image-wrap{
			@include absolute(top 0 left 0);
			@include size(100%, 100%);
			white-space: nowrap;
			text-align: center;

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include size(0, 100%);
			}

			&:after{
				content: '';
				@include absolute(top 0 left 0);
				@include size(100%, 100%);
				background: rgba(0,0,0, .052);
				z-index: 1;
			}

			img{
				display: inline-block;
				vertical-align: middle;
				max-width: 100%;
				max-height: 100%
			}
		}
	}

	.gs-mobile-gallery-nav{
		@include absolute(top 50% left 0);
		@include size(100%, auto);
		z-index: 1;
		margin-top: -8px;
		display: none;
		
		@include media(tablet){
			display: block;
		}

		span{
			@include absolute(top 0);
			font-size: 16px;
			font-weight: bold;
			

			&.gs-prev{
				left: 15px;
			}

			&.gs-next{
				right: 15px;
			}
		}
	}
}

.gs-rel-pr-title{
	h5{
		text-transform: uppercase;
		font-family: $font-main;
		font-size: 14px;
	}
}