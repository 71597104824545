.gs-floated-grid.gs-about-grid{

	.gs-grid-item{
		margin: 0 0 30px;

		@include media(tablet){
			margin: 0 0 20px;
		}

		@include media(mobile){
			margin: 0 0 10px;
		}
	}
	
	.gs-item-image{
		padding-top: 80%;
		cursor: pointer;
		@include transition(all .3s);

		@include media(hover){
			&:hover{
				opacity: .7;
			}
		}
	}
}

.gs-about-media{
	margin-top: 30px;

	.gs-video-title{
		font-size: 22px;
	}

	.gs-about-video{
		width: 100%;

		.gs-about-vid-container{
			@include relative();
			padding-bottom: 56.25%;
			@include size(100%, 100%);
		}

		iframe{
			@include absolute(top 0 left 0);
			@include size(100%, 100%);
		}
	}

}

.gs-contacts-detail{
	font-size: 0;
}

.gs-google-map{
	@include relative();
	@include size(100%, 440px);
	margin-top: 25px;

	div{
		position: initial;
	}
}

.gs-contact-info{
	@include size(100%, auto);
	margin-top: 25px;

	ul{
		@extend .gs-list;
		font-size: 0;
	}

	li{
		display: inline-block;
		vertical-align: top;
		width: 33.3333%;
		padding: 10px 25px 10px 0;
		font-size: 12px;

		@include media(mobile){
			width: 100%;
		}

		strong{
			display: block;
			font-size: 14px;
			text-transform: uppercase;
			font-weight: normal;
			margin-bottom: 5px;
		}

		a{
			text-decoration: underline;
		}
	}
}

.gs-about-image{
	margin-top: 25px;

	img{
		max-width: 100%;
	}
}

.gs-contact-form{
	@include relative();
	width: 100%;
	margin-top: 25px;

	h5{
		font-size: 14px;
		font-family: $font-main;
		text-transform: uppercase;
	}

	.gs-form-wrap{
		margin: 0 -15px;
	}
	
	.gs-field-box{
		width: 100%;
		margin: 20px 0 0;
		float: left;
		padding: 0 15px;

		&.half-width{
			width: 50%;

			@include media(mobile){
				width: 100%;
			}
		}
	}

	input.gs-text-fld{
		border:1px solid;
		@include size(100%, 40px);
		padding: 0 10px;
		font-size: 12px;
	}

	.gs-textarea-fld{
		@include size(100%, 140px);
		border:1px solid;
		padding: 10px;
		font-size: 12px;
	}

	.gs-error-msg{
		@include absolute(bottom 0 left 0);
		@include size(100%, auto);
		text-align: center;
		z-index: 1;
		padding: 20px 10px;
		display: none;
	}

	.gs-success-msg{
		@include absolute(bottom 0 left 0);
		@include size(100%, auto);
		text-align: center;
		z-index: 1;
		padding: 20px 10px;
		display: none;

		span{
			display: block;
			padding: 0 20px;
		}
	}

	.gs-contact-gdpr{
		text-align: left;
		padding: 0 0 2px;
		display: inline-block;
		border-bottom: 1px solid transparent;
		line-height: 1.2;

		&.error{
			border-color: red;
		}

		a{
			text-decoration: underline;
		}

		input.gs-gdpr-check{
			@include absolute(top 2px left 0);
			display: inline-block;
			z-index: 1;
		}

		span{
			display: inline-block;
			vertical-align: middle;
			padding-left: 18px;
			font-size: 12px;
		}


	}
}