.gs-shop-gallery{
	@include fixed(bottom 0 left 0);
	@include size(100%, 100%);
	overflow: hidden;
	visibility: hidden;
	opacity: 0;
	z-index: 10;
	display: none;

	&:before{
		content: '';
		@include absolute(top 0 left 0);
		@include size(100%, 100%);
		pointer-events: none;
		background: rgba(0,0,0,.052);
		z-index: 5;
	}

	.gs-pop-slider{
		@include relative();
		@include size(100%, 100%);
	}

	.gs-gallery-wrapper{
		@include relative();
		@include size(100%, 100%);
	}

	.gs-preload-img{
		@include absolute(top 50% left 0);
		@include size(100%, auto);
		text-align: center;
	}

	.gs-close-gallery{
		@include absolute(top 15px right 15px);
		font-size: 22px;
		z-index: 2;
		cursor: pointer;
		visibility: hidden;
		opacity: 0;

		span{
			display: block;
			@include transition(all .5s);

			@include media(hover){
				&:hover{
					@include rotate(-135deg);
				}
			}
		}
		
	}

	.gs-zoom-gallery{
		@include absolute(top 0 right 35px);
		font-size: 20px;
		z-index: 2;
		cursor: pointer;
		@include transition(all .5s);
		
		@include media(hover){
			&:hover{
				opacity: .7;
			}
		}
	}

	.gs-pop-slider{
		visibility: hidden;
		opacity: 0;
	}

	.gs-gall-slide{
		text-align: center;
		overflow: hidden;
		padding: 0 65px;

		img{
			max-width: 100%;
			width: auto;
    		display: inline-block;
    		height: auto;
    		max-height: 100%;
		}

		.gs-video-frame{
			position:relative;
			padding: 30px 0 56.6% 0;
			height:0;
			top: 50%;
    		transform: translateY(-50%);
			overflow:hidden;

			iframe{
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:100%;
				padding: 10% 20%;

				@include media(tablet){
					padding: 5%;
				}
			}
		}
	}

	.swiper-pagination-progress{
		@include absolute(bottom 0 top auto);
		@include size(100%, 5px);
		z-index: 10;
	}

	.gs-swiper-nav{
		@include absolute(top 50% left 0);
		@include size(100%, auto);
		margin-top: -15px;
		z-index: 3;
		visibility: hidden;
		opacity: 0;

		@include media(tablet){
			top: 90%;
		}

		span{
			@include absolute(top 0);
			display: inline-block;
			font-size: 22px;
			cursor: pointer;
			@include transition(all .5s);

			&.gs-sw-prev{
				left: 15px;

				@include media(hover){
					&:hover{
						@include transformX(-5px);
					}
				}
			}

			&.gs-sw-next{
				right: 15px;

				@include media(hover){
					&:hover{
						@include transformX(5px);
					}
				}
			}
		}
	}
}