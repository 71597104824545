.gs-floated-grid{
	position: relative;
	width:100%;
	font-size: 0;


	.gs-grid-wrap{
		margin: 0 -15px;
		z-index: 1;

		@include media(tablet){
			margin: 0 -10px;
		}

		@include media(mobile){
			margin: 0 -5px;
		}
	}

	/* Basic Grid Style */
	.gs-grid-item{
		position: relative;
		width: 100%;
		display: inline-block;
		vertical-align: top;
		padding: 0 15px;
		margin: 0 0 15px;

		@include media(tablet){
			padding: 0 10px;
		}

		@include media(mobile){
			padding: 0 5px;
		}

		@include media(hover){
			&:hover{

				.gs-item-image{

					img{
						@include scale(1.05);
					}

					.gs-double-view{
						img.gs-main-image{
							visibility: hidden;
							opacity: 0;
						}
					}

					.gs-second-view{
						visibility: visible;
						opacity: 1;
					}
				}

			}
		}
	}

	&.two-col{	
		.gs-grid-item{
			width: 50%;
		}
	}

	&.three-col{
		.gs-grid-item{
			width: 33.33%;
			
			@include media(mobile){
				width: 50%;
			};
		}
	}

	&.four-col{
		.gs-grid-item{
			width: 25%;

			@include media(tablet-portrait){
				width: 33.33%;
			};

			@include media(mobile){
				width: 50%;
			};
		}
	}

	&.five-col{
		.gs-grid-item{
			width: 20%;

			@include media(laptop){
				width: 25%;
			};

			@include media(tablet){
				width: 33.33%;
			};

			@include media(mobile){
				width: 50%;
			};
		}
	}

	.gs-wq-btn-wrap{
		@include absolute(right 0 top 15px);
		text-align: right;
		@include transition(all .5s);
		z-index: 2;

		a{
			display: inline-block;
			vertical-align: top;
			font-size: 19px;

			&.gs-qw-button{
				display: none;

				@include media(tablet){
					display: none !important;
				}
			}
		}
	}

	.gs-item-image{
		@include size(auto, 0);
		padding-top: 120%;
		overflow: hidden;
		
		.gs-item-view{
			@include absolute(top 0 left 0);
			@include size(100%,100%);
			text-align: center;
			font-size: 0;
			white-space: nowrap;

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include size(1%, 100%);
				margin-left: -1%;
			}
		}

		.gs-pattern{
			opacity: .052;
		}

		img{
			max-height: 100%;
			max-width: 100%;
			display: inline-block;
			vertical-align: middle;
			@include transition(all .5s $easeInOutSine);
			backface-visibility: hidden;
		}

		.gs-second-view{
			@include absolute(left 0 top 0);
			@include size(100%, 100%);
			visibility: hidden;
			opacity: 0;
			@include transition(all .5s $easeInOutSine);

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include size(1%, 100%);
				margin-left: -1%;
			}
		}
	}

	.gs-item-text{
		font-size: 15px;
		text-align: left;
		padding: 15px 50px 15px 0;

		@include media(tablet){
			padding: 15px 25px 15px 0;
		}

		a.gs-item-title{
			height: 32px;
			overflow: hidden;
			display: block;
			margin-bottom: 0;
		}
	}

	.gs-item-price{
		@include size(100%, 37px);
		overflow:hidden;
		line-height: 1;

		span{
			display: inline-block;
			font-size: 16px;
			margin-top: 3px;

			&.gs-old-price{
				font-size: 14px;
				text-decoration: line-through;
				padding-right: 5px;
			}
		}
	}
	
}

.gs-tags{
	@include absolute(top 5px left 5px);
	width: calc(100% - 10px);
	z-index: 3;
	text-align: left;

	em{
		white-space: nowrap;
		font-style: normal;
		font-size: 28px;
		@include size(35px, 18px);
		display: inline-block;
		position: relative;
		backface-visibility: hidden;

		span{
			@include absolute(top 1px left 0);
			display: block;
			text-align: center;
			font-size: 14px;
			width: 100%;
			backface-visibility: hidden;
		}

		&.gs-promo{
			@include absolute(top 0 right 0);
		}
	}
}