/* On Scroll Animations For Desktop*/

@include media(hover){
	.gs-anim-block{

		.gs-elem{
			@include transition(all 1s);
			visibility: hidden;
			opacity: 0;

			&.done{
				visibility: visible;
				opacity: 1;
			}

			&.top{
				@include transformY(150px);

				&.done{
					@include transformY(0);
				}
			}

			&.left{
				@include transformX(-150px);

				&.done{
					@include transformY(0);
				}
			}

			&.right{
				@include transformX(150px);

				&.done{
					@include transformY(0);
				}
			}

			&.scale{
				@include scale(.8);

				&.done{
					@include scale(1);
				}
			}
		}

	}
}

@keyframes loading {
  0% {
    box-shadow: 0px 0px 0px 8px #fff;
    width: 0%;
    height: 0%;
    opacity: 0;
  }

  20%{
    opacity: 1;
  }
  
  100% {
    box-shadow: 0px 0px 0px 8px #fff;
    width: 100%;
    height: 100%;
  }
}

@keyframes blink{
    0% { opacity:1; }
    50% { opacity:0; }
    100% { opacity:1; }
}

@keyframes circle {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    width: 2000px;
    height: 2000px;
    opacity: 0;
  }
}

/* Spinner Animation */

$offset: 187;
$duration: 1.4s;

@keyframes rotator {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(270deg); }
}

@keyframes dash {

 0% { 
 	stroke-dashoffset: $offset; 
 }
 50% {
 	stroke-dashoffset: $offset/4;transform:rotate(135deg);
 }
 100% {
   stroke-dashoffset: $offset;
   transform:rotate(450deg);
 }

}

/* Spinner Animation */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}



































