.gs-live-events{

    .gs-events-state{
        margin: 20px 0;
        padding: 20px 0;
    }

    .gs-event-item{
        display: flex;
        padding: 0 0 30px;
        margin: 0 0 30px;
        border-bottom: 1px solid #e5e5e5;
        
        @include media(tablet-portrait){
            display: block;
        }

        &:last-child{
            border-bottom: 0;
        }
    }

    .gs-event-text{
        margin-top: 10px;
        max-width: 650px;

        @include media(tablet-portrait){
            margin-top: 20px;
        }
    }

    .gs-event-image{
        flex: 0 0 30%;
        width: 30%;
        margin-right: 20px;
        cursor: pointer;
        overflow: hidden;

        @include media(tablet-portrait){
            flex: 0 0 100%;
            width: 100%;
            margin-right: 0;
        }

        img{
            max-width: 100%;
            @include transition(all .5s);
        }

        @include media(hover){
            &:hover{
                img{
                    @include scale(1.05);
                }
            }
        }
    }

    .gs-event-date{
        color: #959595;
        display: block;
        font-style: normal;
    }

    .gs-event-title{
        margin-top: 20px;
        cursor: pointer;
    }

    .gs-event-description{
        margin-top: 15px;
    }

    .gs-event-watch{
        margin-top: 20px;
        display: inline-flex;
        align-items: center;
        justify-content: center;

        .icon{
            font-size: 18px;
            margin: -2px 10px 0 0;
        }
    }

}

.gs-event-popup{
    @include fixed(top 0 left 0);
    @include size(100%, 100%);
    z-index: 99999999999;
    overflow: hidden;
    display: none;

    .bg-pattern{
        @include fixed(top 0 left 0);
        @include size(100%, 100%);
        background-color: rgba(#000, .75);
    }

    .gs-event-video-container{
        @include size(100%, 100%);
        white-space: nowrap;
        padding: 20px;
        text-align: center;
        overflow: auto;

        &:before{
            content: '';
            @include size(0, 100%);
            display: inline-block;
            vertical-align: middle;
        }
    }
    
    .gs-event-content{
        display: inline-block;
        vertical-align: middle;
        background-color: #fff;
        white-space: normal;
        text-align: left;
        width: 100%;
        max-width: 1000px;
        pointer-events: all;

        @include media(tablet-portrait) {
            background: #fff;
            @include fixed(top 0 left 0);
            @include size(100%, 100%);
            padding: 56.25% 0 0;

        }
    }

    .swiper-container-vertical > .swiper-wrapper{
        -webkit-box-orient: horizontal;
        flex-direction: row;

        @include media(tablet-portrait){
            -webkit-box-orient: vertical;
            flex-direction: column;
        }
    }

    .gs-event-player{
        @include size(100%, 0);
        @include relative();
        padding-top: 56.25%;

        @include media(tablet-portrait) {
            @include fixed(top 0 left 0);
            z-index: 1;
        }
    }

    .gs-yt-player{
        @include absolute(top 0 left 0);
        @include size(100%, 100%);
    }

    .gs-event-products{
        padding: 20px;
        border-top: 1px solid #efefef;
        overflow: hidden;

        @include media(tablet-portrait){
            padding: 15px 15px 55px 15px;
            height: 100%;
        }
    }

    .swiper-container{
        @include media(tablet-portrait){
            height: 100%;
        }
    }

    .swiper-wrapper{
        @include media(tablet-portrait){
            flex-wrap: wrap;
            height: auto;
        }
    }

    .swiper-slide{
        width: auto;
        margin-right: 20px;

        @include media(tablet-portrait){
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
            height: auto;

            &.demo{
                width: 100%;
            }
        }

        &.focus{
            .gs-event-product{
                box-shadow: 0 0 15px 0px rgb(0 0 0 / 15%);
                @include transformY(-5px);
                border-color: #cdcdcd;

                @include media(tablet-portrait){
                    @include transformY(0);
                }

                &:after{
                    opacity: 1;
                }
            }
        }
    }

    .gs-event-product{
        display: flex;
        align-items: flex-start;
        width: 360px;
        background: #fff;
        border: 1px solid transparent;
        box-shadow: 0 0 15px 0px rgb(0 0 0 / 5%);
        transition: transform .5s;

        @include media(tablet-portrait){
            width: 100%;
        }

        &:after{
            content: '\e914';
            font-family: 'icomoon' !important;
            @include absolute(top 3px right 7px);
            color: #e5c057;
            font-size: 20px;
            opacity: 0;
        }
    }

    .gs-product-image{
        flex: 0 0 40.786%;
        width: 40.786%;
        height: 0;
        padding-top: 40.786%;

        @include media(tablet-portrait){
            flex: 0 0 35%;
            width: 35%;
            padding-top: 35%;
        }
        
        &:after{
            content: '';
            @include absolute(top 0 left 0);
            @include size(100%, 100%);
            background: #2e2e2e;
            opacity: .12;
            pointer-events: none;
        }
    }

    .gs-product-info{
        flex-grow: 1;
        padding: 20px 20px 50px 20px;

        @include media(mobile){
            overflow: hidden;
        }
    }

    .gs-product-title{
        @include media(mobile){
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }

    .gs-product-price{
        margin-top: 6px;
        display: inline-block;

        span{
            display: inline-block;
            font-size: 15px;
            margin-top: 3px;

            &.gs-old-price{
                color: #959595;
                font-size: 14px;
                text-decoration: line-through;
                padding-right: 5px;
            }
        }
    }

    .product-bottom{
        @include absolute(bottom 0 right 0);
        width: 60%;

        @include media(tablet-portrait){
            width: 65%;
        }
    }

    .gs-tags{
        top: auto;
        bottom: 15px;
        left: 20px;
        width: 75px;
        display: flex;
        justify-content: space-between;

        em{
            position: relative;

            @include media(mobile){
                width: 26px;
            }
        }

        @include media(mobile){
            width: 56px;
        }
    }

    .gs-product-btn{
        @include absolute(bottom 15px right 20px);
        font-size: 19px;
    }

    .skeleton{
        @include absolute(top 0 left 0);
        @include size(100%, 100%);
        background-color: #ededed;
        z-index: 2;
        overflow: hidden;

        &:after{
            content: "";
            @include absolute(top 0 left 0 bottom 0 right 0);
            transform: translateX(-100%);
            background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.1) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
            animation: skeleton-loading 1s infinite;
        }
    }
    
    .gs-close-event{
        @include absolute(top 20px right 20px);
        @include size(40px, 40px);
        border-radius: 500px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        z-index: 1;
        cursor: pointer;
        font-size: 20px;

        @include media(tablet-portrait){
            top: auto;
            bottom: 0;
            right: 0;
            width: 100%;
            z-index: 2;
            border-top: 1px solid #ddd;
            border-radius: 0;
        }

        @include media(hover){
            @include transition(all .5s);

            &:hover{
                @include rotate(-90deg);
            }
        }
    }
}

@keyframes skeleton-loading {
    100% {
        transform: translateX(100%);
    }
}