body.cart-cart .gs-footer-separator{
	display: none;
}

.gs-wrap-cart{
	font-size: 0;

	.gs-section-wrap{
		padding: 0;
	}
}

.gs-cart-container{
	display: inline-block;
	vertical-align: top;
	width: 65%;
	border-right: 1px solid;

	@include media(tablet-portrait){
		width: 100%;
		border-right: 0;
	}
}

.gs-cart-preloader{
	height: 300px;
}

.gs-cart-action{
	display: inline-block;
	vertical-align: top;
	width: 35%;
	padding-bottom: 35px;
	visibility: hidden;
	opacity: 0;

	@include media(tablet-portrait){
		width: 100% !important;
		position: relative !important;
		bottom: auto !important;
	}

	.gs-cart-total{
		font-size: 12px;
		border-bottom: 1px solid;
		padding: 20px 0 20px 20px;

		@include media(tablet-portrait){
			padding: 20px 0;
		}

		strong{
			float: right;
		}
	}

	.gs-cart-buttons{
		margin-top: 20px;
		padding-left: 15px;

		@include media(tablet-portrait){
			padding-left: 0;
		}

		a.gs-view-more{
			margin: 10px 2% 0 0;
			font-size: 12px;
			width: 49%;
			text-align: center;
			padding: 10px 3px;

			&:last-child{
				margin: 10px 0 0;
			}

			@include media(medium){
				width: 100%;
				margin: 10px 0 0;
			}

			@include media(tablet-portrait){
				width: 49%;
				margin: 10px 2% 0 0;
			}

			@include media(mobile-portrait){
				width: 100%;
				margin: 10px 0 0;
			}
		}
	}
}

.gs-no-cart-items{

	h6{
		padding: 40px 0 30%;
		font-size: 16px;
		font-family: $font-main;
	}
}

.gs-cart-table{
	@include relative();
	width: 100%;
	text-align: left;


	.gs-cart-row{
		@include relative();
		width: 100%;
		border-bottom: 1px solid;

		&:last-child{
			border-width: 0;

			@include media(tablet-portrait){
				border-width: 0 0 1px 0;
			}
		}

	}

	.gs-cart-cell{
		padding: 25px 0;

		@include media(mobile-portrait){
			padding: 25px 0 20px;
		}
	}

	.gs-order-label{
		margin-right: 5px;
	}

	.gs-order-img{
		@include relative();
		display: inline-block;
		vertical-align: top;
		@include size(160px, auto);

		@include media(mobile-portrait){
			width: 80px;
		}

		&:after{
			content: '';
			@include absolute(top 0 left 0);
			@include size(100%, 100%);
			background: rgba(0,0,0, .052);
		}

		img{
			@include size(100%, auto);
		}
	}

	.gs-order-title{
		@include relative();
		display: inline-block;
		vertical-align: top;
		width: calc(100% - 160px);
		padding: 0 100px 100px 20px;

		@include media(mobile-portrait){
			padding: 0 0 0 15px;
			width: calc(100% - 80px);
		}
		
		h6{
			font-size: 16px;
			font-family: $font-main;
		}

		.gs-order-price{
			font-size: 12px;
			margin-top: 7px;

			em{
				font-style: normal;
				margin-right: 5px;
			}
		}
	}

	.gs-order-substr{
		@include absolute(bottom 25px left 180px);

		@include media(mobile-portrait){
			@include relative(bottom 0 left 0);
			margin-top: 15px;
		}
	}

	.gs-order-info{

		.gs-info-row{
			font-size: 12px;
			display: inline-block;
			vertical-align: middle;
			padding-right: 10px;
			margin: 5px 10px 0 0;
			border-right: 1px solid;

			&:last-child{
				padding-right: 0;
				margin: 5px 0 0 0;
				border-right: none;
			}
		}

		span{
			margin-right: 5px;
			display: inline-block;
			vertical-align: middle;
		}

		strong{
			font-weight: normal;
			display: inline-block;
			vertical-align: middle;
		}
	}

	.gs-order-sum{
		margin-top: 5px;
		font-size: 12px;

		span{
			display: inline-block;
			vertical-align: middle;
		}
	}

	.gs-order-label{
		font-size: 12px;
		display: inline-block;
		vertical-align: middle;
	}

	.gs-order-quantity{
		-webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    margin-top: 5px;

		.gs-quantity-change{
			z-index: 1;
			font-size: 0;
			display: inline-block;
			vertical-align: middle;

			input{
				@include size(35px, 15px);
				padding: 0 5px;
				display: inline-block;
				vertical-align: middle;
				border: 0;
				font-size: 12px;
			}

			span.gs-qtty-ch{
				display: inline-block;
				vertical-align: middle;
				font-size: 15px;
				padding: 0 5px;
				cursor: pointer;
			}		
		}

		a.gs-confirm-sum{
			display: inline-block;
			vertical-align: middle;
			border-left: 1px solid;
			font-size: 10px;
			padding: 0 0 0 10px;
			margin-left: 10px;
		}

	}

	.gs-order-remove{
		@include absolute(top 25px right 25px);
		font-size: 12px;

		@include media(mobile-portrait){
			@include relative(top 0 right 0);
			margin-bottom: 20px;
		}
	}
}