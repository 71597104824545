/* arimo-regular - cyrillic_latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 400;
  src: url('fonts/arimo-v9-cyrillic_latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Arimo'),
       url('fonts/arimo-v9-cyrillic_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/arimo-v9-cyrillic_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/arimo-v9-cyrillic_latin-regular.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-italic - cyrillic_latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 400;
  src: url('fonts/arimo-v9-cyrillic_latin-italic.eot'); /* IE9 Compat Modes */
  src: local('Arimo Italic'), local('Arimo-Italic'),
       url('fonts/arimo-v9-cyrillic_latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/arimo-v9-cyrillic_latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/arimo-v9-cyrillic_latin-italic.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-700 - cyrillic_latin */
@font-face {
  font-family: 'Arimo';
  font-style: normal;
  font-weight: 700;
  src: url('fonts/arimo-v9-cyrillic_latin-700.eot'); /* IE9 Compat Modes */
  src: local('Arimo Bold'), local('Arimo-Bold'),
       url('fonts/arimo-v9-cyrillic_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/arimo-v9-cyrillic_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-700.woff') format('woff'), /* Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/arimo-v9-cyrillic_latin-700.svg#Arimo') format('svg'); /* Legacy iOS */
}
/* arimo-700italic - cyrillic_latin */
@font-face {
  font-family: 'Arimo';
  font-style: italic;
  font-weight: 700;
  src: url('fonts/arimo-v9-cyrillic_latin-700italic.eot'); /* IE9 Compat Modes */
  src: local('Arimo Bold Italic'), local('Arimo-BoldItalic'),
       url('fonts/arimo-v9-cyrillic_latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('fonts/arimo-v9-cyrillic_latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-700italic.woff') format('woff'), /* Modern Browsers */
       url('fonts/arimo-v9-cyrillic_latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
       url('fonts/arimo-v9-cyrillic_latin-700italic.svg#Arimo') format('svg'); /* Legacy iOS */
}

@font-face {
    font-family: 'FreeSans';
    src: url('fonts/FreeSans.eot');
    src: url('fonts/FreeSans.eot?#iefix') format('embedded-opentype'),
        url('fonts/FreeSans.woff2') format('woff2'),
        url('fonts/FreeSans.woff') format('woff'),
        url('fonts/FreeSans.ttf') format('truetype'),
        url('fonts/FreeSans.svg#FreeSans') format('svg');
    font-weight: normal;
    font-style: normal;
}


@font-face {
    font-family: 'FreeSans';
    src: url('fonts/FreeSansOblique.eot');
    src: url('fonts/FreeSansOblique.eot?#iefix') format('embedded-opentype'),
        url('fonts/FreeSansOblique.woff2') format('woff2'),
        url('fonts/FreeSansOblique.woff') format('woff'),
        url('fonts/FreeSansOblique.ttf') format('truetype'),
        url('fonts/FreeSansOblique.svg#FreeSansOblique') format('svg');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'FreeSans';
    src: url('fonts/FreeSansBold.eot');
    src: url('fonts/FreeSansBold.eot?#iefix') format('embedded-opentype'),
        url('fonts/FreeSansBold.woff2') format('woff2'),
        url('fonts/FreeSansBold.woff') format('woff'),
        url('fonts/FreeSansBold.ttf') format('truetype'),
        url('fonts/FreeSansBold.svg#FreeSansBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'FreeSans';
    src: url('fonts/FreeSansBoldOblique.eot');
    src: url('fonts/FreeSansBoldOblique.eot?#iefix') format('embedded-opentype'),
        url('fonts/FreeSansBoldOblique.woff2') format('woff2'),
        url('fonts/FreeSansBoldOblique.woff') format('woff'),
        url('fonts/FreeSansBoldOblique.ttf') format('truetype'),
        url('fonts/FreeSansBoldOblique.svg#FreeSansBoldOblique') format('svg');
    font-weight: bold;
    font-style: italic;
}


@font-face {
    font-family: 'icomoon';
    src:  url('fonts/icomoon.eot?5nomsd');
    src:  url('fonts/icomoon.eot?5nomsd#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?5nomsd') format('truetype'),
    url('fonts/icomoon.woff?5nomsd') format('woff'),
    url('fonts/icomoon.svg?5nomsd#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-tiktok:before {
    content: "\e92f";
}
.icon-icon-file:before {
    content: "\e90f";
}
.icon-right-arrow:before {
    content: "\e909";
}
.icon-left-arrow:before {
    content: "\e90d";
}
.icon-borica:before {
    content: "\e92e";
}
.icon-american-express:before {
    content: "\e926";
}
.icon-diners-club:before {
    content: "\e927";
}
.icon-discover:before {
    content: "\e928";
}
.icon-jcb:before {
    content: "\e929";
}
.icon-maestro:before {
    content: "\e92a";
}
.icon-mastercard:before {
    content: "\e92b";
}
.icon-paypal:before {
    content: "\e92c";
}
.icon-visa:before {
    content: "\e92d";
}
.icon-trash:before {
    content: "\e925";
}
.icon-icon-search:before {
    content: "\e90e";
}
.icon-ico_cart:before {
    content: "\e905";
}
.icon-scroll-top:before {
    content: "\e908";
}
.icon-plus-zoom:before {
    content: "\e917";
}
.icon-youtube:before {
    content: "\e921";
}
.icon-instagram:before {
    content: "\e920";
}
.icon-full-screen:before {
    content: "\e91f";
}
.icon-video:before {
    content: "\e916";
}
.icon-bag:before {
    content: "\e918";
}
.icon-pin:before {
    content: "\e919";
}
.icon-pin-it:before {
    content: "\e91a";
}
.icon-placeholder:before {
    content: "\e91b";
}
.icon-tick:before {
    content: "\e91c";
}
.icon-user:before {
    content: "\e91d";
}
.icon-google-plus:before {
    content: "\e91e";
}
.icon-arrow-right:before {
    content: "\e901";
}
.icon-up:before {
    content: "\e922";
}
.icon-prev:before {
    content: "\e913";
}
.icon-next:before {
    content: "\e923";
}
.icon-down:before {
    content: "\e924";
}
.icon-promo:before {
    content: "\e900";
}
.icon-star:before {
    content: "\e914";
}
.icon-ico_grid:before {
    content: "\e911";
}
.icon-ico_mail:before {
    content: "\e910";
}
.icon-mail:before {
    content: "\e90b";
}
.icon-right:before {
    content: "\e912";
}
.icon-down-direction:before {
    content: "\e915";
}
.icon-up-direction:before {
    content: "\e90c";
}
.icon-grid:before {
    content: "\e90a";
}
.icon-close:before {
    content: "\e907";
}
.icon-ico_eye:before {
    content: "\e902";
}
.icon-ico_facebook:before {
    content: "\e903";
}
.icon-ico_phone:before {
    content: "\e904";
}
.icon-ico_twitter:before {
    content: "\e906";
}
