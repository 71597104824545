@charset 'UTF-8';

/* 1. Configuration and helpers */
@import
  'utils/variables',
  'utils/functions',
  'utils/mixins';

/* 2. Vendors */
@import
  'vendor/normalize.scss';

/* 3. Base stuff */
@import
  'base/fonts.scss',
  'base/typography.scss',
  'base/helpers.scss',
  'base/animations.scss',
  'base/base.scss';

/* 4. Layout-related sections */
@import
  'layout/header.scss',
  'layout/footer.scss';

/* 5. Components */
@import
  'components/buttons.scss',
  'components/swipper.scss',
  'components/grids.scss',
  'components/tabs.scss',
  'components/add_to_cart_form.scss',
  'components/sidebar.scss',
  'components/pop_up_gallery.scss',
  'components/errors.scss',
  'components/pagination.scss',
  'components/reviews.scss',
  'components/banner.scss';

/* 6. Page-specific styles */
@import
  'pages/home.scss',
  'pages/catalogue.scss',
  'pages/contact.scss',
  'pages/detail.scss',
  'pages/faq.scss',
  'pages/news.scss',
  'pages/news_detail.scss',
  'pages/cart.scss',
  'pages/broadcast.scss';