.gs-products-slider{
	overflow: hidden;
	padding: 0 6px;

	@include media(mobile){
		padding: 0;
		margin: 0 -6px;
	}

	
	.gs-swiper-slide{
		@include size(auto, auto);
		padding: 0 6px;
	}

	.gs-item-data{

		@include media(hover){
			&:hover{

				.gs-item-image{

					img{
						@include scale(1.05);
					}

					.gs-double-view{
						img.gs-main-image{
							visibility: hidden;
							opacity: 0;
						}
					}

					.gs-second-view{
						visibility: visible;
						opacity: 1;
					}
				}

			}
		}
	}

	.gs-wq-btn-wrap{
		@include absolute(right 0 top 15px);
		text-align: right;
		@include transition(all .5s);
		z-index: 2;

		a{
			display: inline-block;
			vertical-align: top;
			font-size: 19px;

			&.gs-qw-button{
				display: none;
			}
		}
	}

	.gs-item-image{
		@include size(auto, 0);
		padding-top: 120%;
		overflow: hidden;
		
		.gs-item-view{
			@include absolute(top 0 left 0);
			@include size(100%,100%);
			text-align: center;
			font-size: 0;

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include size(1%, 100%);
				margin-left: -1%;
			}
		}

		.gs-pattern{
			opacity: .052;
			backface-visibility: hidden;
			z-index: 1;
		}

		img{
			max-height: 100%;
			max-width: 100%;
			display: inline-block;
			vertical-align: middle;
			@include transition(all .5s $easeInOutSine);
			backface-visibility: hidden;
		}

		.gs-second-view{
			@include absolute(left 0 top 0);
			@include size(100%, 100%);
			visibility: hidden;
			opacity: 0;
			@include transition(all .5s $easeInOutSine);

			&:before{
				content: '';
				display: inline-block;
				vertical-align: middle;
				@include size(1%, 100%);
				margin-left: -1%;
			}
		}


	}

	.gs-item-text{
		font-size: 15px;
		text-align: left;
		padding: 15px 50px 15px 0;

		a.gs-item-title{
			height: 32px;
			overflow: hidden;
			display: block;
			margin-bottom: 0;
		}
	}

	.gs-item-price{
		@include size(100%, 37px);
		overflow:hidden;
		line-height: 1;

		span{
			display: inline-block;
			font-size: 16px;
			margin-top: 3px;

			&.gs-old-price{
				font-size: 14px;
				text-decoration: line-through;
				padding-right: 5px;
			}
		}
	}
}

.gs-products-navigation{
	@include absolute(top 35% left 0);
	@include size(100%, auto);
	z-index: 1;

	@include media(mobile){
		display: none;
	}

	&.no-navigation{
		display: none;
	}

	.gs-nav-btn{
		display: inline-block;
		@include absolute(top 0);
		@include transition(all .5s);
		font-size: 24px;

		&.gs-prev{
			left:0;
		}

		&.gs-next{
			right:0;
		}
	}
}


.gs-home-title{
	padding: 6%;
	border: 1px solid #e5e5e5;

	@include media(mobile){
		padding: 30px;
	}


	h1{
		font-size: 24px;
		font-weight: bold;

	}

	.gs-rtf{
		line-height: 1.4;
		margin-top: 20px;
	}
}


