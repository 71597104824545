.gs-header{
	@include fixed(top 0 left 0);
	@include size(100%, 100px);
	z-index: 10;
	backface-visibility: hidden;
	border-bottom: 1px solid;

	@include media(tablet){
		height: 75px;
	}
}
							
.gs-main-nav{
	@include relative();
	@include size(100%, 100%);
	max-width: 1210px;
	padding: 0 15px;
	margin: 0 auto;
	font-size: 0;
	z-index: 1;
}

.gs-main-nav-actions{
	@include size(40%, 100%);
	display: inline-block;
	vertical-align: middle;

	@include media(tablet){
		@include absolute(top 0 right 15px);
		width: auto;
		white-space: nowrap;
		z-index: 1;
	}

	ul{
		@extend .gs-list;
		position: relative;
		@include size(100%, 100%);
		text-align: right;
	}

	li{
		display: inline-block;
		vertical-align: middle;
		margin-left: 12px;
		height: 100%;

		@include media(tablet){
			&.gs-profile-btn, &.gs-search-btn{
				display: none;
			}
		}

		@include media(mobile-portrait){
			margin-left: 6px;
		}

		&:before{
			content:'';
			display: inline-block;
			vertical-align: middle;
			@include size(1px, 100%);
			margin-left: -1px;
		}

		a{
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			text-transform: uppercase;
			@include transition(all .5s);
		}
	}

	.gs-profile-btn-menu{
		display: inline-block;
		vertical-align: middle;

		&:hover{
			.gs-profile-btn-wrap{
				display: block;
			}
		}

		.gs-profile-hover{
			font-size: 12px;
			text-transform: uppercase;
			padding: 10px 12px 10px 0;
			position: relative;
			cursor: pointer;

			.icon{
				display: inline-block;
				vertical-align: middle;
				font-size: 8px;
				@include absolute(top 50% right 0);
				margin-top: -4px;
			}
		}

		.gs-profile-btn-wrap{
			@include absolute(top 100% left 50%);
			padding: 10px 15px;
			@include size(100px, auto);
			margin: 5px 0 0 -50px;
			background-color: #fff;
			border: 1px solid #e5e5e5;
			text-align: left;
			display: none;
		}

		a{
			margin: 5px 0;
			text-transform: none;
			white-space: nowrap;

			&:hover{
				.icon{
					margin-right: 3px;
				}
			}

			.icon{
				font-size: 12px;
			}

			span{
				display: inline-block;
				vertical-align: middle;
				margin-right: 0;
				@include transition(all .3s);
			}
		}
	}
}

.gs-lang-menu{
	position: relative;
	font-size: 12px;
	text-transform: uppercase;
	cursor: pointer;
	display: inline-block;
	vertical-align: middle;

	&.active{
		.gs-lang-list{
			visibility: visible;
			opacity: 1;
		}
	}

	span{
		display: block;
		padding-right: 10px;
	}

	em{
		display: inline-block;
		@include absolute(top 50% right 0);
		font-size: 8px;
		margin-top: -5px;
		@include transition(all .5s);
	}

	.gs-lang-list{
		@extend  .gs-list;
		@include absolute(left 50% top 100%);
		margin: 5px 0 0 -25px;
		@include size(50px, auto);
		text-align: center;
		border: 1px solid;
		opacity: 0;
		visibility: hidden;
		@include transition(all .5s);

		a{
		    display: block;
		    font-size: 12px;
		    padding: 5px;
		    position: relative;
		    border-bottom: 1px solid;

		    &:last-child{
		    	border-bottom: 0;
		    }

		    &:after{
		    	content: '';
				@include absolute(top 50% left 0);
				@include size(0, 1px);
				@include transition(all .5s);
		    }

		    &.active:after{
		    	width: 100%;
		    }

		}
	}
}

.gs-logo{
	display: inline-block;
	vertical-align: middle;
	@include size(20%, 100%);
	padding: 10px 15px;
	z-index: 1;
	text-align: center;

	@include media(tablet){
		width: 100%;
		display: block;
	}

	.gs-logo-wrap{
		@include size(100%, 100%);
		white-space: nowrap;
		max-width: 230px;
		margin: 0 auto;
	}

	.gs-logo-img{
		display: inline-block;
		height: 100%;
		@include transition(all .5s);

		a{
			position: relative;
			display: block;
			height: 100%;
			overflow: hidden;

			&:before{
				content:'';
				display: inline-block;
				vertical-align: middle;
				@include size(1px, 100%);
				margin-left: -1px;
			}
		}

		span{
			font-size: 24px;
			font-weight: bold;
		    display: inline-block;
		    vertical-align: middle;
		    white-space: normal;
		    line-height: 1;
			font-family: $font-heading;
		}

		img{
			display: inline-block;
			vertical-align: middle;
			max-width : 100%;
			max-height: 100%;
		}
	}
}

.gs-cart-done{
	@include absolute(top 50% left 50%);
	@include transform(-50%, -50%);
	@include size(50px, 50px);
	border-radius: 100px;
	background: rgba(0,0,0,.2);
	pointer-events: none;
	visibility: hidden;
	opacity: 0;
}

.gs-cart{
	display: inline-block;
	vertical-align: middle;
	white-space: nowrap;
	position: static;

	a{
		@include relative();
		display: inline-block;
		vertical-align: middle;
		font-size: 12px;
	}

	.gs-cart-text{
		font-weight: normal;
		display: inline-block;
		vertical-align: top;

		@include media(tablet){
			display: none;
		}
	}

	.gs-mobile-cart{
		font-size: 18px;
		display: none;
		vertical-align: middle;
		margin-top: -2px;

		@include media(tablet){
			display: inline-block;
		}

	}

	.gs-cart-qtty{
		display: inline-block;
		vertical-align: top;

		@include media(tablet){
			@include absolute(top -9px right -9px);
			@include size(18px, 18px);
			border-radius: 50px;
			text-align: center;
			line-height: 18px;
			font-size: 10px;
		}

		em{
			font-style: normal;

			@include media(tablet){
				display: none;
			}
		}
	}
}

.gs-cart-box{
	@include absolute(top 100% right 0);
	@include size(365px, auto);
	border: 1px solid;
	text-align: left;
	white-space: normal;
	max-height: calc(100vh - 100px);
	overflow-x: hidden;
	visibility: hidden;
	opacity: 0;
	@include transition(all .3s);

	&.active{
		visibility: visible;
		opacity: 1;
	}

	.gs-cart-item{
		@include relative();
		width: 100%;
		padding: 15px 10px;
		border-bottom: 1px solid;

		.gs-cart-cell{
			padding-left: 115px;
			z-index: 1;
			min-height: 100px;
			
			.gs-cart-title{
				font-size: 15px;
    			display: block;
    			padding: 0 25px 35px 0;
    			line-height: 18px;
			}

			.gs-cart-qtty{
				font-size: 12px;
				@include absolute(top 2px right 0);
			}

			.gs-cart-price{
				font-size: 16px;
				font-style: normal;
    			display: block;
    			@include absolute(bottom 2px left 115px);
			}

			&.gs-cell-img{
				@include absolute(top 15px left 10px);
				@include size(100px, 100px);
				font-size: 0;
				white-space: nowrap;
				text-align: center;
				padding-left: 0;

				&:before{
					content: '';
					display: inline-block;
					vertical-align: middle;
					@include size(0, 100%);
				}

				&:after{
					content: '';
					@include absolute(top 0 left 0);
					@include size(100%, 100%);
					background: rgba(0,0,0,.052);
					z-index: 1;
				}

				img{
					display: inline-block;
					vertical-align: middle;
					max-height: 100%;
					max-width: 100%;
				}
			}
		}
	}

	.gs-cart-total{
		position: relative;
		width: 100%;
		padding: 15px 10px;
		overflow: hidden;

		a.gs-view-more{
			display: block;
			text-align: center;
		}
	}

}

.gs-show-search{

	&.active{
		&:after{
			width: 100%;
		}
	}
	

	&:after{
		content:'';
		@include absolute(top 48% left 0);
		@include size(0, 0);
		border-bottom: 1px solid;
		@include transition(all .5s);
	}
}

.gs-search{
	@include fixed(top 100px left 0);
	@include size(100%, auto);
	border: 1px solid;
	border-width: 0 0 1px 0; 
	@include transition(all .5s);
	visibility: hidden;
	opacity: 0;

	@include media(tablet){
		@include relative(top 0 left 0);
	}

	&.active {
		visibility: visible;
		opacity: 1;
	}
	
}

.gs-search-form{
	@include relative();
	max-width: 650px;
	margin: 0 auto;
	padding: 20px 15px;

	@include media(tablet){
		max-width: 100%;
		padding: 15px;
	}

	.gs-field-box{
		@include relative();
		@include size(100%, auto);
		font-size: 18px;

		input.gs-text-field{
			@include size(100%, 35px);
			padding: 0 25px 0 35px;
			border:1px solid;
			border-width: 0 0 1px 0;
			font-size: 14px;

			@include media(tablet){
				padding: 0 35px 0 5px;
			}
		}

		button{
			@include absolute(top 8px left 3px);
			background: transparent;
			border:none;
			outline: none;
			font-size: 16px;

			@include media(tablet){
				left: auto;
				right: 3px;
			}
		}
	}

	.gs-close-search{
		@include absolute(top 11px right 0);
		font-size: 16px;
		cursor: pointer;

		@include media(tablet){
			display: none;
		}
	}
}

.gs-nav-wrap{
	position: relative;
	@include size(40%, 100%);
	white-space: nowrap;
	display: inline-block;
	vertical-align: middle;

	@include media(tablet){
		@include fixed(top 74px left 0);
		@include size(100%, 100%);
		@include transition(all .5s);
		background: #fff;
		max-height: calc(100% - 75px);
    	overflow-x: hidden;
    	visibility: hidden;
    	opacity: 0;

    	&.active{
    		visibility: visible;
    		opacity: 1;

    		.gs-search{
    			visibility: visible;
    			opacity: 1;
    		}
    	}
	}
}

.gs-head-links{
	display: inline-block;
	vertical-align: middle;
	@include size(100%, 100%);
	white-space:normal;

	@include media(tablet){
		display: block;
		height: auto;
	}

	nav{
		position: relative;
		@include size(100%, 100%);
	}

	ul{
		@extend .gs-list;
		position: relative;
		white-space: nowrap;
		@include size(100%, 100%);

		li{
			display: inline-block;
			vertical-align: middle;
			margin-right: 12px;
			height: 100%;
			white-space: normal;

			@include media(tablet){
				display: block;
				margin-right: 0;
			}

			&.gn-user-login-0, &.gn-index-index-0, &.gn-user-welcome-0, &.gn---0{
				display: none;

				@include media(tablet){
					display: block;
				}
			}

			&.gn-catalog-details-0{
				@include media(tablet){
					
					&.active{
						z-index: 1;

						.gs-cat-nav{
							left:0;
							visibility: visible;
							opacity: 1;
						}
					}

					a.gs-menu-link{
						padding: 15px 50px 15px 15px;

						&:after{
							content: "\e909";
		    				font-family: 'icomoon' !important;
		    				@include absolute(top 15px right 15px);
		    				font-size: 15px;
		    			}
					}

				}
			}

			@include media(hover){
				&:hover{
					.gs-cat-nav{
						visibility: visible;
						opacity: 1;
					}
				}
			}

			&:before{
				content:'';
				display: inline-block;
				vertical-align: middle;
				@include size(1px, 100%);
				margin-left: -1px;

				@include media(tablet){
					display: none;
				}
			}
		}

		a{
			display: inline-block;
			vertical-align: middle;
			font-size: 12px;
			text-transform: uppercase;
			@include transition(all .5s);

			@include media(tablet){
				display: block;
				padding: 15px;
				border: solid;
				border-width: 0 0 1px 0;
			}

			@include media(hover){
				&:hover{
					text-decoration: underline;
				}
			}

			&.active{
				text-decoration: underline;
			}
		}
	}
}

.gs-cat-nav{
	@include fixed(top 99px left 0);
	@include size(100%, auto);
	max-height: calc(100% - 100px);
    overflow-x: hidden;
    padding: 30px 0 5px;
    border: solid;
	border-width: 1px 0 1px 0;
    visibility: hidden;
    opacity: 0;

    @include media(tablet){
    	@include fixed(top 139px left 100%);
    	max-height: calc(100% - 139px);
    	padding: 0;
    	border-bottom: 0;
		border-width: 0;
    	@include transition(all .3s);
    }

    .gs-mobile-title{
    	font-size: 14px;
    	text-transform: uppercase;
    	text-align: center;
    	padding: 15px;
    	border: solid;
    	border-width: 1px 0 1px 0;
    	display: none;
    	
    	@include media(tablet){
    		display: block;
    	}

    	a{
    		padding: 0;
    		border:0;
    	}
    	
    	span{
    		@include absolute(top 0 left 0);
    		@include size(50px, 100%);
    		font-size: 15px;
    		text-align: left;
    		white-space: nowrap;

    		&:before{
    			display: inline-block;
    			vertical-align: middle;
    			padding-left: 15px;
    		}

    		&:after{
    			content:'';
    			display: inline-block;
    			vertical-align: middle;
    			@include size(0, 100%);
    		}
    	}
    }
}

.gs-cat-nav-cont{
	@include size(100%, auto);
	max-width: 1210px;
	margin: 0 auto;
	padding: 0 15px;
	min-height: 220px;
	font-size: 0;

	@include media(tablet){
		padding: 0;
	}

	.gs-banner-scheme{
		display: inline-block;
		width: 20%;
		vertical-align: top;
		margin-bottom: 35px;

		@include media(tablet){
			display: none;
		}

		.gs-banner-item{
			width: 100%;
			padding: 0;

			.gs-item-image{
				@include transition(all .2s);
			}

			@include media(hover){
				&:hover{
					.gs-item-image{
						@include transformY(0);
						opacity: .85;
					}
				}
			}
		}

		.gs-text-wrap{
			margin-top: 10px;

			.gs-read-more{
				margin-top: 10px;
				font-size: 11px;
			}
		}

		h5{
			a{
				font-size: 18px;
				text-transform: none;

				@include media(hover){
					&:hover{
						text-decoration: none;
					}
				}
			}
		}

	}
}

.gs-menu-wrap{
	@include relative();
	@include size(80%, auto);
	margin: 0 -5px;
	display: inline-block;
	vertical-align: top;

	@include media(tablet){
		width: 100%;
		margin: 0;
		display: block;
	}
}

.gs-menu-item{
	display: inline-block;
	width: 16.66667%;
	vertical-align: top;
	font-family: $font-accent;
	padding: 0 10px 25px;

	@include media(tablet){
		display: block;
		width: 100%;
		padding: 0;
	}

	.gs-sub-icon{
		@include absolute(top 15px right 15px);
		font-size: 15px;
		display: none;
		

		@include media(tablet){
			display: block;
		}
	}
	
	a.gs-main-link{
		font-size: 15px;
		margin-bottom: 10px;
		text-transform: none;
		font-weight: bold;
		display: block;
		@include transition(all .5s);

		@include media(tablet){
			font-weight: normal;
			font-size: 14px;
			margin-bottom: 0;
			padding: 15px 40px 15px 15px
		}
	}
	
	.gs-sub-menu{

		@include media(tablet){
	    	@include fixed(top 139px left 100%);
	    	@include size(100%, 100%);
	    	@include transition(all .3s);
	    	max-height: calc(100% - 139px);
	    	overflow-x: hidden;
	    	padding: 0;
	    	border-bottom: 0;

	    	&.active{
				left:0;
				z-index: 1;
			}
		}

		ul{
	    	@extend .gs-list;
	    	position: relative;

	    	li{
	    		display: block;
	    		height: auto;

	    		@include media(hover){
	    			&:hover{

	    				z-index: 1;

		    			.gs-sub-menu{
		    				visibility: visible;
		    				opacity: 1;
		    				top:100%;
		    			}
	    			}
	    		}

				a.gs-sub-links{
					display: block;
					text-transform: none;
					font-size: 12.99px;
					padding: 0 0 5px;
					@include transition(all .3s);
					position: relative;

					@include media(tablet){
						padding: 15px;
						font-size: 14px;
					}

				}

	    	}
		}

		.gs-sub-menu{
			@include absolute(top 100% left 0);
			@include size(150px, auto);
			border:1px solid;
			border-width: 1px;
			padding: 5px 10px;
			visibility: hidden;
			opacity: 0;
			@include transition(all .3s);
			min-width: auto;

			@include media(tablet){
				@include fixed(top 139px left 100%);
		    	@include size(100%, 100%);
		    	@include transition(all .3s);
		    	max-height: calc(100% - 139px);
		    	overflow-x: hidden;
		    	visibility: visible;
		    	opacity: 1;
		    	padding: 0;
		    	border: 0;

		    	&.active{
					left:0;
					z-index: 1;
				}
			}

			ul{
				width: 100%;
				padding: 0;
			}


			li{
				display: block;
				margin: 5px 0;
				width: 100%;
			}

			a.gs-sub-links{
				padding: 0;

				@include media(tablet){
					padding: 15px;
					font-size: 14px;
				}
			}
		}
	}
}

.gs-open-nav{
	@include absolute(top 0 left 15px);
	@include size(auto, 100%);
	display: none;
	z-index: 2;

	@include media(tablet){
		display: block;
	}

	a{
		@include transition(.5s all);
		display: block;
		text-align: center;
		height:14px;
		@include relative(top 50%);
		margin-top: -7px;
	}

	span{
		display: inline-block;
		@include size(22px, auto);
		@include relative();
	}

	em{
		display: block;
		position: absolute;
		@include size(100%, 1px);
		@include transition(all .5s $easeInOutQuart);

		&.c-1{
			top:0;
		}

		&.c-2{
			top:6px;
		}

		&.c-3{
			top:12px;
		}
	}

	a.active{
		em{
			&.c-1{
				top:6px;
				@include rotate(45deg);
			}

			&.c-2{
				@include transformX(-10px);
				opacity: 0;
			}

			&.c-3{
				top:6px;
				@include rotate(-45deg);
			}
		}
	}

	a.gs-menu-btn.close-cat{
		@include rotate(-180deg);
	}
}



