.gs-reviews-component{
    &.loading{
        .reviews-list-container{
            &:before{
                opacity: 1;
                pointer-events: all;
            }

            &:after{
                opacity: 1;
            }
        }
    }
}

.gs-product-review-container{

    .gs-section-title{
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .gs-title-col{
        display: flex;
        align-items: baseline;
    }

    .gs-col-title{
        line-height: 1;
        font-family: $font-heading;
        font-size: 14px;
        text-transform: uppercase;
    }

    .reviews-count{
        margin: 0 0 0 5px;
        opacity: 0;
        color: #959595;
        text-transform: lowercase;
        font-size: 14px;

        &.active{
            opacity: 1;
        }
    }

    .no-items{
        font-size: 16px;
        font-style: italic;
        color: #616161;
        display: block;
        margin: 0 0 35px;
    }

    .gs-success-message{
        color: #4caf50;
        padding: 22px 0;
        font-size: 16px;
        font-style: italic;
        display: none;
    }

    .gs-error-message{
        @include absolute(left 0 bottom 0);
        width: 100%;
        background-color: red;
        color: #fff;
        padding: 20px;
        opacity: 0;
        pointer-events: none;
        transition: opacity .3s;
        z-index: 1;

        &.active{
            opacity: 1;
            pointer-events: all;
        }
    }

    .preloader{
        @include relative();
        padding: 80px 0;
        display: flex;
        justify-content: center;
        width: 100%;

        &:after{
            content: '';
            @include size(30px, 30px);
            display: block;
            border: 2px solid var(--accentColor);
            animation: reviews-rotation 1s infinite linear;
            display: block;
            border-radius: 50%;
            border-bottom-color: transparent;
        }
    }

    .gs-product-review-item{
        margin-top: 1.5rem;
        padding-top: 1.5rem;
        border-top: 1px solid #dddddd;
    }

    .gs-verified-review{
        padding: 3px 6px;
        font-size: 11px;
        background-color: var(--accentColor);
        color: #fff;
        margin: 0 0 0 10px;
        display: inline-block;
        vertical-align: bottom;
        white-space: nowrap;
        font-weight: normal;
    }

    .gs-person-name{
        font-family: $font-main;
        font-weight: bold;
        display: flex;
        align-items: baseline;
        justify-content: space-between;
        line-height: 1;
        font-size: 16px;

        @media (min-width:576px) {
            justify-content: flex-start;
        }
    }

    .gs-reviews-rating{
        display: flex;
        align-items: baseline;
        margin-top: 10px;
    }

    .gs-review-stars{
        display: flex;
        align-items: center;
    }

    .star-icon{
        color: #515151;
        font-size: 27px;
        position: relative;
        padding-right: 3px;
        line-height: 1;

        input{
            display: none;
        }

        &.active{
            color: #FFC107FF;
        }

        &:has(~ .active){
            color: #FFC107FF;
        }
    }

    .gs-review-date{
        color: #959595;
        font-size: 12px;
    }

    .gs-review-comment{
        margin-top: 15px;
    }

    .gs-review-title{
        font-weight: bold;
        margin-bottom: 10px;
    }
}

.reviews-list-container{
    position: relative;

    &:before{
        content: '';
        @include size(100%, 100%);
        @include absolute(top 0 left 0);
        background-color: rgba(#FFFFFF, .5);
        z-index: 1;
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s;
    }

    &:after{
        content: '';
        @include absolute(top 50% left 50%);
        margin: -15px 0 0 -15px;
        @include size(30px, 30px);
        display: block;
        border: 2px solid var(--accentColor);
        animation: reviews-rotation 1s infinite linear;
        display: block;
        border-radius: 50%;
        border-bottom-color: transparent;
        pointer-events: none;
        opacity: 0;
        transition: opacity .3s;
        z-index: 1;
    }
}

.gs-review-form-wrapper{
    margin-top: 1.5rem;
    padding-top: 1.5rem;
    border-top: 1px solid #dddddd;

    .gs-view-more{
        outline: none !important;
        box-shadow: none !important;

        &.loading{
            pointer-events: none;
            color: transparent;

            &:after{
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -12px 0 0 -12px;
                width: 24px;
                height: 24px;
                border: 2px solid;
                border-color: #fff #fff transparent;
                border-radius: 50px;
                display: inline-block;
                box-sizing: border-box;
                animation: reviews-rotation 1s linear infinite;
            }
        }
    }
}

.gs-review-form {
    position: relative;
    width: 100%;
    display: none;
    max-width: 650px;

    .gs-form-label {
        color: #616161;
        text-transform: uppercase;
        font-size: 10px;
        display: block;
    }

    .gs-form-row{
        display: flex;
        flex-wrap: wrap;
        margin: 0 -10px;

        .half{
            width: 100%;
            flex: 0 0 100%;
            padding: 0 10px;

            @media (min-width:576px) {
                width: 50%;
                flex: 0 0 50%;
            }
        }
    }

    .gs-fld-box {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .gs-review-stars {
        display: flex;
        align-items: center;
    }

    .star-icon {
        color: #515151;
        font-size: 36px;
        position: relative;
        padding-right: 5px;
        line-height: 1;
        cursor: pointer;
        transition: color .3s;

        &.active {
            color: #FFC107FF;
        }

        &:has(~ .active) {
            color: #FFC107FF;
        }

        &.focus {
            color: #FFC107FF !important;
        }

        &:has(~ .focus) {
            color: #FFC107FF !important;
        }

        &.focus ~ .star-icon {
            color: #515151 !important;
        }
    }

    .form-control {
        @include size(100%, 36px);
        background-color: #fff;
        border: 1px solid #d3d3d3;
        padding: 0 10px;
        border-radius: 0;
        margin-top: 5px;

        &:focus {
            outline: none;
        }

        &.form-area {
            height: 120px;
            padding: 10px;
        }
    }
}

.gs-product-rating{
    display: flex;
    align-items: flex-end;
    margin: 0 0 5px;
    cursor: pointer;

    .gs-stars{
        display: flex;
        align-items: center;
        position: relative;
    }

    .gs-active-stars{
        display: flex;
        align-items: center;
        @include size(0, 100%);
        @include absolute(top 0 left 0);
        overflow: hidden;

        .star-icon {
            color: #FFC107FF;
        }
    }

    .star-icon {
        color: #515151;
        
        font-size: 22px;
        position: relative;
        line-height: 1;
    }

    .gs-rating{
        margin: 0 0 2px 5px;
        font-weight: 500;
        line-height: 1;
        font-size: 12px;
        color: #737373;

    }
}

.gs-reviews-filter{
    display: inline-flex;
    position: relative;
    justify-content: flex-end;

    .gs-order-by-label{
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        max-width: 142px;

        @media (min-width:576px) {
            max-width: inherit;
        }
    }

    .gs-label-text{
        margin: 0 10px 0 0;
        width: 100%;
        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        .helps{
            font-size: 9px;
            margin-right: 5px;
            font-weight: bold;
        }
    }

    .gs-order-by-items{
        @include absolute(top 100% right 0);
        @include size(230px, auto);
        background-color: #fff;
        box-shadow: 0px 7px 6px rgba(0, 0, 0, 0.05);
        border: 1px solid #dddddd;
        border-radius: 4px;
        z-index: 2;
        padding: 10px 0;
        display: none;

        &.active{
            display: block;
        }
    }

    .gs-order-by-item{
        display: flex;
        align-items: center;
        padding: 4px 18px;
        cursor: pointer;

        @media (min-width:768px) {
            &:hover{
                text-decoration: underline;
            }
        }

        &.active{
            text-decoration: underline;
        }

        .helps{
            font-size: 12px;
            font-weight: bold;
            margin-right: 5px;
        }
    }
}

@keyframes reviews-rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}