.gs-floated-grid.gs-news-gallery{
	margin: 2px 0 0;

	.gs-grid-wrap{
		margin: 0 -2px;
		font-size: 0;
	}

	.gs-grid-item{
		margin: 0 0 2px;
    	padding: 0 2px;
    	width: 25%;

		@include media(mobile){
			width:33.33334%;
		}
	}

	img{
		max-width: 100%;
	}
}

#gs-default-gallery{

	img{
		max-width: 100%;
		cursor: pointer;
		@include transition(opacity .5s);
		backface-visibility: hidden;

		@include media(hover){
			&:hover{
				opacity: .7;
			}
		}
	}
	
}

.gs-def-slider{
	@include fixed(top 0 left 0);
	@include size(100%, 100%);
	z-index: 50;
	background: rgba(255,255,255, .85);
	visibility: hidden;
	opacity:0;

	.gs-preloader{
		background: transparent;
	}

	.gs-def-gallery-close{
		@include absolute(top 15px right 15px);
		font-size: 22px;
	    z-index: 2;
	    cursor: pointer;
	    visibility: hidden;
	    opacity: 0;

	    span.icon-close{
	    	font-weight: bold;
	    	display: block;
	    	@include transition(transform .5s);

	    	@include media(hover){
	    		&:hover{
	    			@include rotate(-135deg);
	    		}
	    	}
	    }
	}

	.gs-swiper-nav{
		@include absolute(top 50% left 0);
		width: 100%;
		margin-top: -15px;
		visibility: hidden;
		opacity: 0;
		z-index: 1;

		@include media(tablet-portrait){
			top: 100%;
			margin-top: -55px;
		}

		span{
			@include absolute(top 0);
			font-size: 22px;
			@include transition(transform .5s);
			cursor: pointer;

			&.gs-sw-prev{
				left: 15px;

				@include media(hover){
		    		&:hover{
		    			@include transformX(-5px);
		    		}
		    	}
			}

			&.gs-sw-next{
				right: 15px;

				@include media(hover){
		    		&:hover{
		    			@include transformX(5px);
		    		}
		    	}
			}
		}
	}

	.swiper-wrapper{
		visibility: hidden;
		opacity: 0;
		@include size(100%, 100%);
	}

	.swiper-slide{
		@include size(100%, 100%);
	    text-align: center;
	    overflow: hidden;
	    padding: 5%;
	    white-space: nowrap;

	    &:before{
	    	content: '';
	    	@include size(0px, 100%);
	    	display: inline-block;
	    	vertical-align: middle;
	    }

	    img{
	    	display: inline-block;
	    	vertical-align: middle;
	    	@include size(auto, auto);
	    	max-width: 100%;
	    	max-height: 100%;
	    }
	}
}

.gs-news-data{
	font-size: 12px;
	margin-bottom: 15px;
}

.gs-news-image{
	max-width: 50%;
	float: left;
	padding: 0 20px 5px 0;

	@include media(tablet-portrait){
		width: 100%;
		max-width: 100%;
		float: none;
		padding: 0 0 20px 0;
	}

	img{
		width: 100%;
	}
}

.gs-news-nav{
	margin-bottom: 30px;

	a:nth-child(2){
		float: right;

		@include media(mobile){
			float: none;
			margin-top: 10px;
		}
	}
}

.demo-container{
	@include absolute(top 0 left 100%);
	@include size(100%, 500px);
	z-index: 5;
}

.gs-news-fb-comments{
	.gs-fb-comments{
		margin-top: 25px;
	}
}

.gs-news-tags{
	margin-top: 20px;

	.gs-tag-item{
		display: inline-block;
		padding: 6px 15px;
		font-weight: normal;
		text-transform: none;
		margin: 5px 5px 0 0;
		border: 1px solid #e5e5e5;
		color: #333;
		border-radius: 4px;
	}
}